import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { AgoField } from "veranstalter/src/components/DateTimeField";
import { StatePublishedField } from "veranstalter/src/components/StatePublishedField";
import { PostsExplainBanner } from "veranstalter/src/components/Banners";
import { Flex } from "veranstalter/src/components/Design";

function PostList(props) {
  return (
    <Flex>
      <PostsExplainBanner />
      <List
        {...props}
        exporter={false}
        sort={{ field: "updated_at", order: "DESC" }}
      >
        <Datagrid rowClick="edit">
          <TextField source="text" label="Text" />
          <StatePublishedField source="state_published" label="Status" />
          <AgoField source="updated_at" label="Bearbeitet" />
          <AgoField source="created_at" label="Erstellt" />
        </Datagrid>
      </List>
    </Flex>
  );
}

export { PostList };
