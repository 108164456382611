import React, { useCallback } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ActionDelete from "@material-ui/icons/Delete";
import { translate, crudDelete, startUndoable } from "ra-core";
import { Typography, Box } from "@material-ui/core";
import { Confirmable } from "veranstalter/src/components/Confirmable";

function DeleteConfirmButton({
  label = "ra.action.delete",
  className,
  titleSource,
  ...rest
}) {
  const handleDelete = useCallback(() => {
    const {
      dispatchCrudDelete,
      startUndoable,
      resource,
      record,
      basePath,
      redirect = "list",
      undoable = "false",
    } = rest;
    if (undoable) {
      startUndoable(
        crudDelete(resource, record.id, record, basePath, redirect)
      );
    } else {
      dispatchCrudDelete(resource, record.id, record, basePath, redirect);
    }
  }, [rest]);
  return (
    <Confirmable
      red={true}
      label="Löschen"
      onConfirm={handleDelete}
      title="Fehlerhafte Veranstaltung Löschen"
      icon={<ActionDelete />}
    >
      <Typography>
        Sobald du eine Veranstaltung löschst, verschwindet sie unwiderruflich
        aus der KULTA App.
      </Typography>
      <Box mt={2}>
        <Typography>
          💡 Du hast auch die Möglichkeit eine Veranstaltung abzusagen.
          InteressentInnen werden dann über die App informiert. Diese Funktion
          findest du rechts neben dem Datum.
        </Typography>
      </Box>
    </Confirmable>
  );
}

export default compose(
  connect(null, { startUndoable, dispatchCrudDelete: crudDelete }),
  translate
)(DeleteConfirmButton);
