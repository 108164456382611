import React, { useState } from "react";
import { Close } from "@material-ui/icons";
import { Flex } from "veranstalter/src/components/Design";
import { Touchable } from "veranstalter/src/components/Touchable";
import { Button } from "veranstalter/src/components/Button";

function OptionalInput({
  children = null,
  reset = () => {},
  iconColor = "#000",
  label = "hinzufügen",
  initialShow = false,
  sxExt = {},
  style = {},
  icon = "add",
  ...props
}) {
  const [showInput, setShowInput] = useState(initialShow);
  return (
    <Flex
      style={{
        opacity: showInput ? 1 : 0.7,
        ...style,
      }}
      sx={{
        position: "relative",
        ...sxExt,
      }}
      {...props}
    >
      {showInput ? (
        <>
          {children}
          <Touchable
            onPress={() => {
              reset();
              setShowInput(false);
            }}
            style={{
              position: "absolute",
              backgroundColor: "#333",
              top: 0,
              right: -8,
              borderRadius: 18,
              width: 18,
              height: 18,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Close style={{ width: 15, height: 15, color: "white" }} />
          </Touchable>
        </>
      ) : (
        <Flex mt={3}>
          <Button
            iconColor={iconColor}
            onClick={() => setShowInput(true)}
            secondary={true}
            // @ts-ignore
            icon={icon}
            children={label}
          />
        </Flex>
      )}
    </Flex>
  );
}

export { OptionalInput };
