import germanMessages from "ra-language-german";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { raFormLayoutLanguageEnglish } from "@react-admin/ra-form-layout";
import { messages as sharedMessages } from "shared/data/messages";

const customMessages = {
  ku: {
    action: {
      delete: "Löschen",
      show: "Anzeigen",
      list: "Liste",
      save: "Speichern",
      create: "Erstellen",
      edit: "Bearbeiten",
      cancel: "Abbrechen",
    },
    title: {},
    text: {},
    f: {
      draft: {
        save: "Speichern",
        save_draft: "Entwurf Speichern",
        save_publish: "Speichern & Veröffentlichen",
        convert_draft: "Zu Entwurf umwandeln.",
        is_draft: "ist aktuell ein Entwurf.",
        is_public: "ist aktuell veröffentlicht",
      },
    },
  },
};

const customResources = {
  profiles: {
    name: "Profil |||| Profile",
    fields: {
      id: "ID",
    },
  },
  posts: {
    name: "Update",
    fields: {
      text: "Text",
      state_published: "Veröffentlicht",
    },
  },
};

const i18nProvider = polyglotI18nProvider(
  (locale) => ({
    ...raFormLayoutLanguageEnglish,
    ...germanMessages,
    ...customMessages,
    ...sharedMessages,
    resources: { ...germanMessages.resources, ...customResources },
  }),
  "de",
  { allowMissing: true, onMissingKey: (key, _, __) => key }
);

export { i18nProvider };
