import { createTheme } from "@material-ui/core/styles";
import { colors } from "../shared/data/colors";
import { Colors } from "shared/utils/theme";

const theme = createTheme({
  spacing: [
    2, 4, 8, 16, 24, 32, 40, 48, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64,
    64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64,
    64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64,
    64, 64, 64, 64, 64, 64,
  ],
  palette: {
    primary: { main: colors.primary.light },
    secondary: { main: colors.accent.light },
    error: { main: "#ff0000" },
    info: { main: colors.blue.light },
    accent: { main: colors.accent.light },
    accentBG: { main: colors.accentBG },
    util: { white: "#FFFFFF", black: "#000000", transparent: "#00000000" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  sidebar: {
    width: 240,
    closedWidth: 240,
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

export { theme };
