import React from "react";

import {
  Box,
  Typography,
  BoxProps,
  TypographyVariant,
} from "@material-ui/core";
import { useTranslate } from "react-admin";

const gradients = {
  darkgrey: "radial-gradient(circle at bottom left, #CFD8DC, #546E7A)",
  soft: "radial-gradient(circle at top left, #CFD8DC, #FAF2F7)",
  warn: "radial-gradient(circle at bottom left, #BF360C, #F57C00)",
  blue: "radial-gradient(circle at bottom left, #2962FF, #3949AB)",
  green: "radial-gradient(circle at bottom left, #2E7D32, #4DB6AC)",
  violet: "radial-gradient(circle at bottom left, #4527A0, #7B1FA2)",
  lightviolet: "radial-gradient(circle at bottom left, #D1C4E9, #F3E5F5)",
  wild: "radial-gradient(circle at bottom left, #D500F9, #FFA000)",
  revolut: "radial-gradient(circle at bottom left, #6200EA, #1976D2)",
  accent: "radial-gradient(circle at bottom left, #FF0047, #a3355d)",
  accentLight: "radial-gradient(circle at bottom left, #f6e9ed, #f4d2df)",
};

function GradientBox({ gradient = "darkgrey", style = {}, ...rest }) {
  return (
    <Flex
      p={3}
      borderRadius={8}
      style={{
        background: gradients[gradient],
        ...style,
      }}
      {...rest}
    />
  );
}

type FlexDirection = "column" | "row";

interface FlexProps extends BoxProps {
  dir?: FlexDirection;
  direction?: FlexDirection | FlexDirection[];
  bg?: string;
}

function _Flex({ dir, direction, bg = undefined, ...rest }: FlexProps, ref) {
  return (
    <Box
      sx={{
        flexDirection: direction || dir || "column",
      }}
      // @ts-ignore
      ref={ref}
      bgcolor={bg}
      display="flex"
      {...rest}
    />
  );
}

const Flex: typeof _Flex = React.forwardRef(_Flex);

interface TextProps extends BoxProps {
  variant?: "inherit" | TypographyVariant;
  color?: string;
  bold?: boolean;
  children?: any;
  t?: string;
}

function TranslateString({ t }) {
  const translate = useTranslate();
  return <>{translate(t)}</>;
}

function MaybeTranslate({ t = undefined, children = undefined }) {
  if (t) {
    return <TranslateString t={t} />;
  } else {
    return children;
  }
}

function _Text(
  {
    variant = undefined,
    color = "inherit",
    bold = false,
    children,
    t = undefined,
    ...rest
  }: TextProps,
  ref
) {
  return (
    <Box
      {...rest}
      // @ts-ignore
      ref={ref}
    >
      <Typography
        variant={variant}
        style={bold ? { fontWeight: "bold" } : {}}
        // @ts-ignore
        color={color}
      >
        {(children || t) && <MaybeTranslate t={t} children={children} />}
      </Typography>
    </Box>
  );
}

const Text: typeof _Text = React.forwardRef(_Text);

export { Flex, Text, GradientBox };
