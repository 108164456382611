import React, { useMemo, useState, useEffect } from "react";
import { Admin, Resource, Loading } from "react-admin";
import { createDataProvider } from "veranstalter/src/utils/dataProvider";
import { createAuthProvider } from "veranstalter/src/utils/authProvider";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import { ApolloProvider } from "@apollo/react-hooks";
import { AuthInfoContext } from "veranstalter/src/utils/AuthInfoContext";
import { i18nProvider } from "veranstalter/src/utils/i18nProvider";
import { Layout } from "veranstalter/src/components/Layout";
import { theme } from "veranstalter/src/utils/theme";
import { ThemeProvider } from "@material-ui/core/styles";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {
  ReportingObserver as ReportingObserverIntegration,
  ExtraErrorData,
  CaptureConsole,
} from "@sentry/integrations";

import {
  EventEdit,
  EventCreate,
} from "veranstalter/src/resources/Event/EventEdit";
import { EventList } from "veranstalter/src/resources/Event/EventList";
import { ProfileEdit } from "veranstalter/src/resources/Profile/ProfileEdit";
import { ProfileCreate } from "veranstalter/src/resources/Profile/ProfileCreate";

import {
  NoticeEdit,
  NoticeCreate,
} from "veranstalter/src/resources/Notice/NoticeEdit";
import { NoticeList } from "veranstalter/src/resources/Notice/NoticeList";
import { PostEdit, PostCreate } from "veranstalter/src/resources/Post/PostEdit";
import { PostList } from "veranstalter/src/resources/Post/PostList";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://9b10a45ae5d0460eaba809d2762380b7@o448305.ingest.sentry.io/5429481",
    integrations: [
      new Integrations.BrowserTracing(),
      new ExtraErrorData(),
      new ReportingObserverIntegration(),
      new CaptureConsole({ levels: ["error"] }),
    ],
    tracesSampleRate: 1.0,
    release: `veranstalter@0.0.1`,
    environment: process.env.NODE_ENV,
  });
}

function AdminApp() {
  const auth0 = useAuth0();
  const [dataProvider, setDataProvider] = useState({
    provider: null,
    client: null,
  });
  const [authInfo, setAuthInfo] = useState(null);
  const authProvider = useMemo(() => createAuthProvider(auth0), [auth0]);
  useEffect(() => {
    if (authInfo) {
      createDataProvider({ rawAuth: authInfo.__raw }).then(setDataProvider);
    }
  }, [authInfo]);
  useEffect(() => {
    auth0.getIdTokenClaims().then((claims) => {
      setAuthInfo(claims);
      Sentry.setUser({ email: claims.email });
    });
  }, [auth0]);
  if (dataProvider.provider !== null) {
    return (
      <ThemeProvider theme={theme}>
        <ApolloProvider client={dataProvider.client}>
          <AuthInfoContext.Provider value={authInfo}>
            <Admin
              loginPage={false}
              layout={Layout}
              dataProvider={dataProvider.provider}
              authProvider={authProvider}
              i18nProvider={i18nProvider}
              theme={theme}
            >
              <Resource
                name="_events_list_veranstalter"
                list={EventList}
                edit={EventEdit}
                create={EventCreate}
              />
              <Resource name="events" edit={EventEdit} create={EventCreate} />
              <Resource name="occurrences" />
              <Resource
                name="profiles"
                edit={ProfileEdit}
                create={ProfileCreate}
              />
              <Resource name="cities" />
              <Resource
                name="notices"
                edit={NoticeEdit}
                create={NoticeCreate}
                list={NoticeList}
              />
              <Resource
                name="posts"
                edit={PostEdit}
                create={PostCreate}
                list={PostList}
              />
            </Admin>
          </AuthInfoContext.Provider>
        </ApolloProvider>
      </ThemeProvider>
    );
  } else {
    return (
      <Loading
        loadingPrimary="Lade Daten"
        loadingSecondary="Login war erfolgreich"
      />
    );
  }
}

function AuthenticatedApp() {
  const auth0 = useAuth0();
  if (auth0.isLoading) {
    return (
      <Loading
        loadingPrimary="Prüfe Account"
        loadingSecondary="Du wirst gleich zum Login oder zu KULTA weitergeleitet"
      />
    );
  } else if (auth0.isAuthenticated) {
    return <AdminApp />;
  } else {
    auth0.loginWithRedirect();
    return (
      <Loading
        loadingPrimary="Abmeldung"
        loadingSecondary="Du wirst gleich zum Login weitergeleitet"
      />
    );
  }
}

class ErrorBoundary extends React.Component<{ onError?: any }> {
  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, info) {
    const { onError } = this.props;

    if (typeof onError === "function") {
      try {
        onError.call(this, error, info ? info.componentStack : "");
      } catch (ignoredError) {}
    }
    this.setState({ error, info });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <div>
          {process.env.NODE_ENV === "development" ? String(error) : "Laden..."}
        </div>
      );
    }

    return children || null;
  }
}

function App() {
  return (
    <Auth0Provider
      domain="kulta.eu.auth0.com"
      clientId="gA7rYkh7IXz24oOcv0rTFFu4VFDMXVnI"
      redirectUri={window.location.href}
    >
      <ErrorBoundary>
        <AuthenticatedApp />
      </ErrorBoundary>
    </Auth0Provider>
  );
}

export { App };
export default App;
