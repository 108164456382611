import React from "react";
import { Edit, Create, SelectInput, SimpleForm, TextInput } from "react-admin";

const statePublishedChoices = [
  { id: "DRAFT", name: "DRAFT" },
  { id: "PUBLIC", name: "PUBLIC" },
  { id: "INTERNAL", name: "INTERNAL" },
];

function NoticeForm(props) {
  return (
    <SimpleForm {...props}>
      <TextInput source="id" disabled fullWidth />
      <TextInput source="title" fullWidth />
      <TextInput source="text" multiline lines={6} fullWidth />
      <TextInput source="gradientFrom" fullWidth />
      <TextInput source="gradientTo" fullWidth />
      <SelectInput
        choices={[
          { id: null, name: "disabled" },
          { id: "occurrence", name: "occurrence" },
          { id: "page", name: "page" },
          { id: "profile", name: "profile" },
          { id: "collage", name: "collage" },
          { id: "url", name: "url" },
          { id: "timeline", name: "timeline" },
          { id: "profileList", name: "profileList" },
          { id: "notificationCenter", name: "notificationCenter" },
          { id: "internal", name: "internal" },
          { id: "categories", name: "categories" },
        ]}
        label="Link Type"
        source="link_resourceType"
        fullWidth
      />
      <TextInput
        label="Link ID / URL / Section"
        source="link_resourceId"
        fullWidth
      />
      <SelectInput
        choices={statePublishedChoices}
        source="statePublished"
        fullWidth
      />
      <TextInput
        source="buttons"
        jsonString={false}
        reactJsonOptions={{
          theme: "tube",
          name: null,
          style: {
            minWidth: 700,
            padding: "24px",
            fontWeight: "bold",
            fontSize: 14,
            borderRadius: 12,
            boxShadow: "0px 0px 20px rgba(0,0,0,0.3)",
          },
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
          quotesOnKeys: false,
          displayObjectSize: false,
          displayArrayKey: false,
          sortKeys: true,
        }}
      />
    </SimpleForm>
  );
}

function NoticeEdit(props) {
  return (
    <Edit {...props}>
      <NoticeForm />
    </Edit>
  );
}

function NoticeCreate(props) {
  return (
    <Create {...props}>
      <NoticeForm />
    </Create>
  );
}

export { NoticeEdit, NoticeCreate };
