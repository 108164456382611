import React, { useState, useMemo, useCallback } from "react";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Cloudinary } from "cloudinary-core";
import drop from "veranstalter/src/img/drop.svg";
import { useInput, InputProps } from "react-admin";
import { Flex, Text } from "veranstalter/src/components/Design";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const createCloudinary = (cloudName, unsignedUploadPreset, onGotHandle) => ({
  load: (source, load, error, progress, abort, headers) => {
    var cl = new Cloudinary({ cloud_name: cloudName });
    var myRequest = new Request(cl.url(source));
    fetch(myRequest).then(function (response) {
      response.blob().then(function (myBlob) {
        load(myBlob);
      });
    });
  },
  process: (fieldName, file, metadata, load, error, progress, abort) => {
    // `fieldName` and `meta` are not used for now

    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.upload.addEventListener("progress", (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    });

    xhr.onreadystatechange = (e) => {
      if (xhr.readyState !== 4) {
        return;
      }

      if (xhr.status >= 200 && xhr.status < 300) {
        const response = JSON.parse(xhr.responseText);
        load(response.public_id);
        onGotHandle(response.public_id);
        return;
      }

      error("oh no!");
    };

    formData.append("upload_preset", unsignedUploadPreset);
    formData.append("tags", "browser_upload");
    formData.append("file", file);
    xhr.send(formData);

    return {
      abort: () => {
        xhr.abort();
      },
    };
  },
  revert: null,
});

const CloudinaryInput = ({
  inputStyle = "photo",
  imageTypeLabel = "Foto",
  style = {},
  ...props
}: {
  imageStyle?: "photo" | "avatar";
  imageTypeLabel?: string;
} & InputProps) => {
  const [forceRender, setForceRender] = useState(0);
  const {
    input: { onChange, value },
    meta: { touched, error },
  } = useInput(props);
  const [files, setFiles] = useState(
    value ? [{ source: value, options: { type: "local" } as const }] : []
  );
  const cloudinaryServer = useMemo(
    () =>
      createCloudinary("hcabadqds", "qrgbqmal", (u) =>
        onChange({ target: { value: u } })
      ),
    [onChange]
  );
  const updateFiles = useCallback(
    (files) => {
      setFiles(files);
      if (files.length === 0) {
        setForceRender((s) => s + 1);
        onChange({ target: { value: "" } });
      }
    },
    [onChange]
  );
  return (
    <Flex style={{ position: "relative", ...style }}>
      <FilePond
        key={forceRender}
        files={files}
        onupdatefiles={updateFiles}
        allowMultiple={false}
        server={cloudinaryServer}
        name="files"
        stylePanelLayout={inputStyle === "avatar" ? "integrated" : "compact"}
        labelIdle={
          inputStyle === "photo"
            ? `
        <div style="display:flex; flex-direction:column; align-items:center; justify-content:space-around; height: 100%; margin-top: 180px;">
          <img style="opacity:0.6; width:64px; height:64px; margin-bottom: 16px;" src=${drop}></img>
          <div>${imageTypeLabel} hier reinziehen oder <span class="filepond--label-action">auswählen</span>${
                props.required ? "*" : ""
              }</div>
        </div>`
            : `
        <div style="display:flex; flex-direction:column; align-items:center; justify-content:space-around; height: 100%;">
          <img style="opacity:0.4; width:32px; height:32px;" src=${drop}></img>
          <div style="font-size: 70%; margin: 8px;">${imageTypeLabel} reinziehen oder <span class="filepond--label-action">auswählen</span>${
                props.required ? "*" : ""
              }</div>
        </div>`
        }
        stylePanelAspectRatio={inputStyle === "photo" ? "4:3" : "1:1"}
        styleProgressIndicatorPosition="center"
        styleLoadIndicatorPosition="center"
      />

      {touched && error && (
        <Text variant="caption" pl={3} color="error" style={{ marginTop: -12 }}>
          {error}
        </Text>
      )}
    </Flex>
  );
};
export { CloudinaryInput };
