import React, { useContext } from "react";
import { AuthInfoContext } from "veranstalter/src/utils/AuthInfoContext";

import { useRedirect, useGetOne } from "react-admin";
import { StatsBox } from "veranstalter/src/components/Boxes";

import { Button } from "@material-ui/core";
import { Flex, Text, GradientBox } from "veranstalter/src/components/Design";
import { useUserID } from "veranstalter/src/utils/AuthInfoContext";
import { useMediaQuery } from "@material-ui/core";
import { Notice } from "veranstalter/src/components/WIPNotice";
import { SystemUpdateAlt } from "@material-ui/icons";

function CompleteImageBanner() {
  const redirect = useRedirect();
  const userID = useUserID();

  return (
    <GradientBox gradient="violet" mb={4} color="util.white">
      <Flex dir="row" ml={1} justifyContent="space-between" alignItems="center">
        <Text variant="h6" flex="1" mr={3}>
          Du hast noch kein Profilblid hochgeladen.
        </Text>
        <Button
          onClick={() => redirect(`/profiles/${userID}`)}
          size="large"
          variant="contained"
          color="inherit"
        >
          <Flex color="util.black">{"Bild hochladen"}</Flex>
        </Button>
      </Flex>
    </GradientBox>
  );
}

function MobileWarningBanner() {
  return (
    <Notice text="Leider ist die mobile Ansicht noch nicht ganz rund. Wir arbeiten dran." />
  );
}

function ScrapedWarningBanner() {
  return (
    <GradientBox gradient="soft" my={2} dir="row" alignItems="center">
      <SystemUpdateAlt />
      <Text ml={3}>
        Veranstaltungen, die automatisch aus externen Quellen importiert werden,
        kannst du nicht bearbeiten. Wenn es damit ein Problem gibt,&nbsp;
        <a href="mailto:hallo@kulta.app">kontaktiere uns</a>.
      </Text>
    </GradientBox>
  );
}

function CompleteDetailsBanner() {
  const redirect = useRedirect();
  const userID = useUserID();
  return (
    <GradientBox gradient="revolut" mb={4} color="util.white">
      <Flex dir="row" ml={1} justifyContent="space-between" alignItems="center">
        <Text variant="h6" flex="1" mr={3}>
          Du hast noch keine Informationen in deinem Profil eingetragen.
        </Text>
        <Button
          onClick={() => redirect(`/profiles/${userID}`)}
          size="large"
          variant="contained"
          color="inherit"
        >
          <Flex color="util.black">{"Profil Ausfüllen"}</Flex>
        </Button>
      </Flex>
    </GradientBox>
  );
}

function ProfileNotApprovedBanner() {
  return (
    <SimpleBanner gradient="blue">
      Dein Profil ist noch nicht freigeschaltet. Du kannst jetzt Events
      einstellen, die auf KULTA erscheinen, sobald wir dich freigeschaltet
      haben.
    </SimpleBanner>
  );
}

function SimpleBanner({ gradient = "revolut", children }) {
  return (
    <GradientBox gradient={gradient} mb={4} color="util.white">
      <Flex dir="row" ml={1}>
        <Text variant="h6" flex="1" mr={3}>
          {children}
        </Text>
      </Flex>
    </GradientBox>
  );
}

function PostsExplainBanner() {
  return (
    <SimpleBanner>
      Poste kurze Updates, um deine Follower auf dem neusten Stand zu halten.
      Follower finden deine Updates unter "Profile" und auf deinem Profil.
    </SimpleBanner>
  );
}

function rot13Encode(str) {
  var output = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var input = "NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm";
  var index = (x) => input.indexOf(x);
  var translate = (x) => (index(x) > -1 ? output[index(x)] : x);
  return str.split("").map(translate).join("");
}

function Banners() {
  const authInfo = useContext(AuthInfoContext);
  const userID = authInfo.sub;
  const { data: profileData } = useGetOne("profiles", userID);
  const mobile = useMediaQuery("(max-width:640px)");

  const approved = profileData && profileData.approved;
  const hasImage = profileData && profileData.image_handle;
  const hasDetails =
    profileData &&
    (profileData.description ||
      profileData.phone ||
      profileData.website ||
      profileData.twitter_username ||
      profileData.facebookPage_url ||
      profileData.instagram_username);
  const noOtherBanner = hasImage && hasDetails && approved && !mobile;

  return (
    <>
      {!hasImage && <CompleteImageBanner />}
      {!hasDetails && <CompleteDetailsBanner />}
      {!approved && <ProfileNotApprovedBanner />}
      {mobile && <MobileWarningBanner />}
      {noOtherBanner && <StatsBox />}
      {profileData.shouldScrape && <ScrapedWarningBanner />}
    </>
  );
}

export { Banners, rot13Encode, PostsExplainBanner };
