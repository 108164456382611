// @ts-nocheck

import React, { useCallback } from "react";
import {
  MenuItemLink,
  useGetOne,
  Link,
  setSidebarVisibility,
  useLogout,
  Button,
} from "react-admin";
import { useRole, useUserID } from "veranstalter/src/utils/AuthInfoContext";
import { CloudinaryAvatar } from "veranstalter/src/components/CloudinaryAvatar";
import {
  ViewList,
  AccountCircle,
  EventNoteRounded,
  HistoryRounded,
  Feedback,
  SystemUpdateAlt,
} from "@material-ui/icons";
import { ContactBox } from "veranstalter/src/components/Boxes";
import { Settings } from "@material-ui/icons";
import { Flex, Text } from "veranstalter/src/components/Design";
import {
  CreateEventButton,
  ImportButton,
} from "veranstalter/src/components/CreateEventButton";
import { FaBullhorn as Bullhorn, FaPowerOff } from "react-icons/fa";

import { useDispatch } from "react-redux";

function EatProps({ children }) {
  return children;
}

function UserSection({ close }) {
  const userID = useUserID();
  const { data } = useGetOne("profiles", userID);
  return (
    <Flex>
      <Flex dir="row" alignItems="center">
        <Link to={`/_events_list_veranstalter`} onClick={close}>
          <CloudinaryAvatar
            handle={data?.image_handle}
            name={data?.name}
            size={48}
          />
        </Link>
        <Flex ml={3}>
          <Link to={`/_events_list_veranstalter`} onClick={close}>
            <Text variant="h6">{data?.name || ""}</Text>
          </Link>
          <Link to={`/profiles/${userID}`} onClick={close}>
            <Text variant="button">Profil bearbeiten</Text>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}

function _MyLink({ href, target, children, style, className }, ref) {
  return (
    <a
      ref={ref}
      href={href}
      target={target}
      children={children}
      style={style}
      className={className}
    />
  );
}

const MyLink = React.forwardRef(_MyLink);

const Menu = () => {
  const userID = useUserID();
  const { data } = useGetOne("profiles", userID);
  const role = useRole();
  const dispatch = useDispatch();
  const close = useCallback(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const logout = useLogout();

  if (data) {
    return (
      <Flex pt={4}>
        <Flex mx={3}>
          <UserSection close={close} />
          <Flex mt={6} mb={3} onClick={close}>
            <CreateEventButton label="Neues Event" />
          </Flex>
          {role === "editor" && <ImportButton />}
        </Flex>
        <MenuItemLink
          to={`/`}
          primaryText="Events"
          leftIcon={<EventNoteRounded />}
        />
        <Flex ml={3}>
          <MenuItemLink
            to={`/_events_list_veranstalter?filter=%7B%22in_past%22%3A%20false%2C%20%22scraped%22%3A%20false%7D`}
            primaryText="Bevorstehend"
            leftIcon={<ViewList />}
          />
          {data.shouldScrape && (
            <MenuItemLink
              to={`/_events_list_veranstalter?filter=%7B%22in_past%22%3A%20false%2C%20%22scraped%22%3A%20true%7D`}
              primaryText="Importiert"
              leftIcon={<SystemUpdateAlt />}
            />
          )}
          <MenuItemLink
            to={`/_events_list_veranstalter?filter=%7B%22in_past%22%3Atrue%7D`}
            primaryText="Vergangen"
            leftIcon={<HistoryRounded />}
          />
        </Flex>
        {role === "editor" && (
          <MenuItemLink
            to={`/posts`}
            primaryText="Updates"
            leftIcon={
              <Feedback style={{ width: 20, height: 20, marginLeft: 3 }} />
            }
          />
        )}
        <MenuItemLink
          to={`/profiles/${userID}`}
          primaryText="Profil"
          leftIcon={<AccountCircle />}
        />
        {role === "editor" && (
          <MenuItemLink
            to={`/notices`}
            primaryText="Notices"
            leftIcon={<Settings />}
          />
        )}
        <MenuItemLink
          component={MyLink}
          to={`https://kulta.app/werben`}
          href={`https://kulta.app/werben`}
          primaryText="Werben"
          leftIcon={
            <EatProps>
              <Bullhorn size={18} style={{ marginLeft: 4 }} />
            </EatProps>
          }
          target="_blank"
        />
        <Flex m={3}>
          <Button
            variant="contained"
            size="large"
            onClick={() => logout()}
            label="Abmelden"
            icon={FaPowerOff}
          />
        </Flex>
        <Flex m={3}>
          <ContactBox />
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex>
        <Flex m={3}>
          <Button
            variant="contained"
            size="large"
            onClick={() => logout()}
            label="Abmelden"
            icon={FaPowerOff}
          />
        </Flex>
        <Flex m={3}>
          <ContactBox />
        </Flex>
      </Flex>
    );
  }
};

export { Menu };
