import React from "react";
import { TextInput, Edit, Create, SimpleForm } from "react-admin";
import { DraftToolbar } from "veranstalter/src/components/DraftToolbar";
import { PostsExplainBanner } from "veranstalter/src/components/Banners";
import { createStyles, makeStyles } from "@material-ui/core";
import { required } from "veranstalter/src/components/Required";
const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "div.te-mode-switch-section": {
        visibility: "hidden",
      },
    },
  })
);

// eslint-disable-next-line
function MarkdownForm({ ...props }) {
  useStyles();
  return (
    <SimpleForm {...props} toolbar={<DraftToolbar />} redirect="list">
      <TextInput
        label="Dein Update"
        source="text"
        fullWidth
        options={{
          //@ts-ignore
          height: "300px",
          toolbarItems: [
            "heading",
            "bold",
            "italic",
            "hr",
            "quote",
            "ul",
            "ol",
            "link",
          ],
        }}
      />
    </SimpleForm>
  );
}

function BasicForm({ ...props }) {
  return (
    <SimpleForm {...props} toolbar={<DraftToolbar />} redirect="list">
      <TextInput
        label="Dein Update"
        source="text"
        validate={required}
        required
        multiline
        rows={8}
        fullWidth
      />
    </SimpleForm>
  );
}

function PostEdit(props) {
  return (
    <>
      <PostsExplainBanner />
      <Edit
        title={"Update bearbeiten"}
        mutationMode="pessimistic"
        warnWhenUnsavedChanges
        redirect="list"
        {...props}
      >
        <BasicForm />
      </Edit>
    </>
  );
}

function PostCreate(props) {
  return (
    <>
      <PostsExplainBanner />
      <Create mutationMode="pessimistic" redirect="list" {...props}>
        <BasicForm />
      </Create>
    </>
  );
}

export { PostEdit, PostCreate };
