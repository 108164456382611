import React from "react";
import { useCallback, useState, useMemo, useEffect, useRef } from "react";
import {
  Edit,
  Create,
  TextInput,
  BooleanInput,
  LinearProgress,
  useGetManyReference,
  useRedirect,
  useRefresh,
  useNotify,
  SimpleForm,
  RadioButtonGroupInput,
  useGetOne,
} from "react-admin";
import { useUserID } from "veranstalter/src/utils/AuthInfoContext";

import {
  Typography,
  Box,
  InputAdornment,
  Link,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { FaSpotify, FaYoutube, FaSoundcloud, FaBlind } from "react-icons/fa";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { CloudinaryInput } from "veranstalter/src/components/CloudinaryInput";
import { ChipRadioInput } from "veranstalter/src/components/ChipRadioInput";
import { Tip } from "veranstalter/src/components/Tip";
import { useQuery, useMutation as useGQLMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import DeleteConfirmButton from "veranstalter/src/components/DeleteConfirmButton";
import { required, Required } from "veranstalter/src/components/Required";
import { Flex, Text } from "veranstalter/src/components/Design";

import {
  OccurrencesInput,
  validateArtDates,
  artDatesToOccurrences,
  makeNewOccurrenceID,
} from "./OccurrencesInput";
import { useImmer } from "use-immer";
import { useField } from "react-final-form";
import { AddressInput } from "veranstalter/src/components/AddressInput";
import { useFormState } from "react-final-form";
import { DraftToolbar } from "veranstalter/src/components/DraftToolbar";
import { OptionalInput } from "veranstalter/src/components/OptionalInput";

const EventTitle = ({ record = undefined }) => {
  return <span>{record ? record.name : ""}</span>;
};

function CategoryInput() {
  const { data } = useQuery(gql`
    query EventTypesList {
      eventTypes {
        name
        eventType
        emoji
        examples
        order
      }
    }
  `);
  if (data) {
    const types = [...data.eventTypes];
    return (
      <ChipRadioInput
        source="eventType"
        choices={types
          .sort((a, b) => a.order - b.order)
          .map(({ eventType, name, emoji, examples }) => ({
            id: eventType,
            name: `${emoji} ${name}`,
            examples,
          }))}
      />
    );
  } else {
    return <LinearProgress />;
  }
}

function EssentialRow() {
  const userID = useUserID();
  const { data } = useGetOne("profiles", userID);
  return (
    <Flex mt={2} mb={3}>
      <Flex direction={["column", "row"]}>
        <Box flex={4} mr={3}>
          <TextInput
            validate={required}
            required
            source="name"
            label="Name der Veranstaltung"
            resource="events"
            fullWidth
          />
        </Box>
        <Box flex={2} mb={3}>
          {data && (
            <AddressInput
              validate={required}
              required
              main={data.main_place_id}
              source="placeID"
              label="Veranstaltungsort"
            />
          )}
        </Box>
      </Flex>
      <CategoryInput />
    </Flex>
  );
}

function BildrechteBoolean(props) {
  const {
    input: { value, onChange, ...rest },
  } = useField(props.source);

  return (
    <Flex style={{ marginTop: -20 }}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={value === "Pressebild"}
            onChange={({ target: { checked } }) => {
              if (checked) {
                onChange({ target: { value: "Pressebild" } });
              } else {
                onChange({ target: { value: "" } });
              }
            }}
            name={rest.name}
          />
        }
        label={props.label}
      />
    </Flex>
  );
}

function OccurrencesRow({ occurrences, updateOccurrences }) {
  const { values } = useFormState();
  return (
    <OccurrencesInput
      isDraftEvent={values.statePublished === "DRAFT"}
      occurrences={occurrences}
      updateOccurrences={updateOccurrences}
      eventID={values.id}
      withMassEdit={values.eventType === "Art"}
    />
  );
}

function InfoRow() {
  const { values } = useFormState();
  return (
    <Flex direction={["column", "row"]}>
      <Flex flex="2">
        <TextInput
          validate={required}
          required
          multiline
          rows={14}
          source="description"
          label="Beschreibung"
          fullWidth
        />
        <Flex direction="row" style={{ marginTop: -14 }} alignItems="center">
          <Flex my={3}>
            <BooleanInput source="online" label="Onlineveranstaltung" />
          </Flex>
          {values.online && (
            <TextInput
              fullWidth
              source="onlineEventURL"
              label="Online Event URL"
            />
          )}
        </Flex>
      </Flex>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        sx={{ ml: [0, 3], mt: 2 }}
      >
        <CloudinaryInput
          validate={required}
          required
          style={{ zIndex: 5, overflow: "hidden" }}
          source="coverImage_handle"
        />
        {values.coverImage_handle && (
          <Flex
            style={{ marginTop: -15, zIndex: 10, backgroundColor: "white" }}
          >
            <TextInput
              style={{ marginTop: -2 }}
              source="coverImage_alt"
              label="Bildbeschreibung"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaBlind />
                  </InputAdornment>
                ),
                endAdornment: (
                  <Tip>
                    <div>Für NutzerInnen mit Seheinschränkung</div>
                    <br />
                    <div>
                      z.B. "Kinoplakat von Bad Boys 2" oder "Adam Green auf
                      einer Bühne"
                    </div>
                  </Tip>
                ),
              }}
            />
            <TextInput
              source="coverImage_credit"
              label="Bildrechte"
              disabled={values.coverImage_credit === "Pressebild"}
              resettable
              style={{ marginTop: -26 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineCopyrightCircle />
                  </InputAdornment>
                ),
              }}
            />
            <BildrechteBoolean source="coverImage_credit" label="Pressebild" />
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

function MediaRow() {
  const { values } = useFormState();
  return (
    <Flex
      mb={3}
      sx={{
        marginTop: [0, -5],
        flexDirection: ["column", "row"],
      }}
    >
      <OptionalInput
        iconColor="#FF0000"
        height={60}
        sxExt={{ width: ["100%", "32%"] }}
        icon="youtube"
        initialShow={Boolean(values.youtubeURL)}
        label="Youtube hinzufügen"
      >
        <TextInput
          source="youtubeURL"
          label="Youtube URL"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaYoutube color="#ff0000" />
              </InputAdornment>
            ),
          }}
        />
      </OptionalInput>
      <OptionalInput
        height={60}
        icon="spotify"
        label="Spotify hinzufügen"
        initialShow={Boolean(values.spotifyURL)}
        sxExt={{ width: ["100%", "32%"], ml: [0, 3] }}
        iconColor="#1DB954"
        ml={3}
      >
        <TextInput
          source="spotifyURL"
          label="Spotify URL"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSpotify color="#1DB954" />
              </InputAdornment>
            ),
          }}
        />
      </OptionalInput>
      <OptionalInput
        height={60}
        icon="soundcloud"
        initialShow={Boolean(values.soundcloudURL)}
        label="Soundcloud hinzufügen"
        sxExt={{ width: ["100%", "32%"], ml: [0, 3] }}
        iconColor="#ff8800"
      >
        <TextInput
          source="soundcloudURL"
          label="Soundcloud URL"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSoundcloud color="#ff8800" />
              </InputAdornment>
            ),
          }}
        />
      </OptionalInput>
    </Flex>
  );
}

function CovidRow() {
  const { values } = useFormState();
  const [showNote, setShowNote] = useState(values.data?.covid?.note);
  const [showURL, setShowURL] = useState(values.data?.covid?.url);
  return (
    <Flex>
      <Text variant="h6">COVID-19</Text>
      <RadioButtonGroupInput
        source="data.covid.proof"
        label="Nachweis"
        defaultValue={""}
        choices={[
          { id: "", name: "Keine Angabe" },
          { id: "proof", name: "Nachweis erforderlich" },
          { id: "noproof", name: "Kein Nachweis erforderlich" },
        ]}
      />
      {values.data?.covid?.proof && (
        <Flex pl={3} style={{ borderLeft: "8px solid #ddd", marginTop: -12 }}>
          <Text variant="body2" bold>
            Was wir anzeigen:
          </Text>
          <Text
            mb={1}
            variant="body1"
            t={`shared.covid.app_${values.data?.covid?.proof}`}
          />
          {showNote ? (
            <TextInput
              multiline
              rows={3}
              source="data.covid.note"
              label="Zusätzliche detailierte Hinweise"
              fullWidth
            />
          ) : (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowNote(true);
              }}
            >
              <Text variant="body2">+ eigenen Hinweis hinzufügen</Text>
            </Link>
          )}
          {showURL ? (
            <TextInput
              source="data.covid.url"
              label="URL von COVID-19 Infoseite"
              fullWidth
            />
          ) : (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowURL(true);
              }}
            >
              <Text variant="body2">
                + URL von COVID-19 Infoseite hinzufügen
              </Text>
            </Link>
          )}
        </Flex>
      )}
    </Flex>
  );
}

function PricingRow() {
  const { values } = useFormState();
  return (
    <Flex maxWidth="400px" position="relative">
      <TextInput
        disabled={values.free}
        multiline
        fullWidth
        rows={3}
        source="price"
        label="Preise"
      />
      <Flex style={{ position: "absolute", top: 14, right: 0 }}>
        <BooleanInput source="free" label="Kostenlos" />
      </Flex>
      <TextInput
        style={{ marginTop: -26 }}
        source="ticketURL"
        label="Ticket URL"
        fullWidth
      />
    </Flex>
  );
}

function EatProps({ children, ...props }) {
  return <Flex>{children}</Flex>;
}

function EventForm({
  occurrences,
  updateOccurrences,
  hasCreate = false,
  hasEdit = false,
  hasList = false,
  hasShow = false,
  isCreate = false,
  toolbar,
  ...props
}) {
  return (
    <SimpleForm
      {...props}
      toolbar={toolbar}
      warnWhenUnsavedChanges
      initialValues={{ eventType: "Other" }}
    >
      <EatProps fullWidth>
        <EssentialRow />
        <OccurrencesRow
          occurrences={occurrences}
          updateOccurrences={updateOccurrences}
        />
        <Typography variant="h6">Infos</Typography>
        <InfoRow />
        <MediaRow />
        <Typography variant="h6">Eintritt</Typography>
        <PricingRow />
        <CovidRow />
      </EatProps>
    </SimpleForm>
  );
}

const UPDATE_OCCURRENCES = gql`
  mutation ($deleted: [bigint!], $created: [occurrences_insert_input!]!) {
    delete_occurrences(where: { id: { _in: $deleted } }) {
      affected_rows
    }
    insert_occurrences(
      objects: $created
      on_conflict: {
        constraint: occurrences_pkey
        update_columns: [startDate, endDate, cancelled, title, data]
      }
    ) {
      affected_rows
    }
  }
`;

function createUpdateMutationInput(eventData, occurrences) {
  if (eventData.eventType === "Art") {
    if (validateArtDates(eventData.data?.artDates)) {
      const newOccurrences = artDatesToOccurrences(eventData.data?.artDates);
      const allOldIds = Object.values(occurrences).map(({ id }) => id);
      return {
        deleted: allOldIds,
        created: newOccurrences.map(({ id, ...o }) => ({
          ...o,
          event_id: eventData.id,
        })),
      };
    } else {
      // eslint-disable-next-line
      throw "Öffnungszeiten und Tage ist nicht vollständig ausgefüllt";
    }
  } else {
    const deleted = Object.values(occurrences)
      .filter(({ isDeleted, isNew }) => isDeleted && !isNew)
      .map(({ id }) => id);
    const created = Object.values(occurrences)
      .filter(({ isDeleted }) => !isDeleted)
      .map(({ id, isNew, startDate, endDate, data, title, cancelled }) => ({
        id: isNew ? undefined : id,
        startDate,
        endDate,
        title,
        data,
        cancelled,
        event_id: eventData.id,
      }));
    return {
      deleted,
      created,
    };
  }
}

function EventEdit(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { data: occurrencesData, loading } = useGetManyReference(
    "occurrences",
    "event_id",
    props.id,
    { page: 1, perPage: 300 },
    { field: "startDate", order: "ASC" },
    {},
    "event"
  );
  const fetchedOnce = useRef(false);
  const [occurrences, updateOccurrences] = useImmer({});
  const occurrencesRef = useRef(null);
  useEffect(() => {
    occurrencesRef.current = occurrences;
  }, [occurrences]);
  const [updateOccurrencesMutation] = useGQLMutation(UPDATE_OCCURRENCES, {});

  const onSuccess = useCallback(
    ({ data }) => {
      try {
        const result = createUpdateMutationInput(data, occurrencesRef.current);
        updateOccurrencesMutation({ variables: result })
          .then(() => {
            notify(`Änderungen gespeichert`, { type: "success" });
            redirect("/");
            refresh();
          })
          .catch((e) => {
            console.error(e);
            notify("Fehler beim Speichern", { type: "error" });
          });
      } catch (e) {
        notify("Fehler beim Speichern", { type: "success" });
      }
    },
    [notify, redirect, refresh, updateOccurrencesMutation]
  );

  useEffect(() => {
    if (
      occurrencesData &&
      fetchedOnce.current !== true &&
      !loading &&
      Object.values(occurrencesData).length > 0
    ) {
      fetchedOnce.current = true;
      updateOccurrences((draft) => {
        Object.values(occurrencesData).forEach(
          ({ id, startDate, endDate, title, cancelled, data }) => {
            draft[String(id)] = {
              id,
              startDate,
              endDate,
              title,
              cancelled,
              data,
              order: new Date(startDate).getTime(),
              isNew: false,
              isDeleted: false,
            };
          }
        );
      });
    }
  }, [loading, occurrencesData, updateOccurrences]);
  return (
    <Flex>
      <Edit
        {...props}
        mutationMode="pessimistic"
        title={<EventTitle />}
        resource="events"
        onSuccess={onSuccess}
      >
        <EventForm
          occurrences={occurrences}
          updateOccurrences={updateOccurrences}
          toolbar={
            <DraftToolbar
              source="statePublished"
              children={(toolbarProps) => (
                <DeleteConfirmButton {...toolbarProps} redirect="list" />
              )}
            />
          }
          {...props}
        />
      </Edit>
      <Required />
    </Flex>
  );
}

function EventCreate(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const initialID = useMemo(() => makeNewOccurrenceID(), []);
  const [occurrences, updateOccurrences] = useImmer({
    [initialID]: {
      isNew: true,
      isDeleted: false,
      id: initialID,
    },
  });
  const occurrencesRef = useRef(null);

  useEffect(() => {
    occurrencesRef.current = occurrences;
  }, [occurrences]);

  const [updateOccurrencesMutation] = useGQLMutation(UPDATE_OCCURRENCES, {});

  const onSuccess = useCallback(
    ({ data }) => {
      try {
        const result = createUpdateMutationInput(data, occurrencesRef.current);
        updateOccurrencesMutation({ variables: result })
          .then(() => {
            notify(`Event erstellt`, { type: "success" });
            redirect("/");
            refresh();
          })
          .catch((e) => {
            console.error(e);
            notify("Fehler beim Speichern", { type: "error" });
          });
      } catch (e) {
        notify("Fehler beim Speichern", { type: "error" });
      }
    },
    [notify, redirect, refresh, updateOccurrencesMutation]
  );

  return (
    <Flex>
      <Create
        {...props}
        title={<EventTitle />}
        resource="events"
        onSuccess={onSuccess}
      >
        <EventForm
          toolbar={
            <DraftToolbar source="statePublished" children={() => null} />
          }
          occurrences={occurrences}
          updateOccurrences={updateOccurrences}
          {...props}
        />
      </Create>
      <Required />
    </Flex>
  );
}

export { EventEdit, EventForm, EssentialRow, EventCreate };
