import React, { useContext, useState } from "react";
import { AuthInfoContext } from "veranstalter/src/utils/AuthInfoContext";
import { Button } from "@material-ui/core";
import { Flex, Text } from "veranstalter/src/components/Design";
import linkToInbox from "link-to-inbox";
import VerifyEmail from "veranstalter/src/img/VerifyEmail.svg";

const supported = [
  "gmail.com",
  "googlemail.com",
  "outlook.com",
  "live.com",
  "hotmail.com",
  "web.de",
  "gmx.de",
  "mail.google.com",
];

function VerifyEmailPrompt() {
  const [didClick, setDidClick] = useState(false);
  const authInfo = useContext(AuthInfoContext);

  return (
    <Flex alignItems="center" width="100%" pt={4} pb={6} px={4}>
      <img src={VerifyEmail} style={{ width: "80%", maxWidth: 320 }} alt="" />
      <Flex style={{ opacity: 0.6 }} py={4} alignItems="center">
        <Text bold variant="h4" textAlign="center">
          E-Mail Adresse bestätigen
        </Text>
        <Text variant="h5" my={1} textAlign="center">
          Bevor es weitergeht, bestätige bitte deine E-Mail Adresse{" "}
          <b>{authInfo.email}</b>. Falls die Mail nicht in den nächsten Minuten
          ankommt, melde dich bei{" "}
          <a style={{ color: "black" }} href="mailto:veranstalter@kulta.app">
            veranstalter@kulta.app
          </a>
          .
        </Text>
      </Flex>
      {supported.includes(linkToInbox.getSpec(authInfo.email).domain) && (
        <Button
          onClick={() => {
            if (!didClick) {
              const link = linkToInbox.getHref(authInfo.email, {
                subject: "Verify your email",
              });
              window.open(link);
              setDidClick(true);
            } else {
              window.location.reload();
            }
          }}
          size="large"
          variant="contained"
          color="inherit"
        >
          <Flex color="util.black">
            {!didClick ? "Postfach öffnen" : "Neu Laden"}
          </Flex>
        </Button>
      )}
    </Flex>
  );
}

export { VerifyEmailPrompt };
