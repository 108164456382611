import React from "react";
import construction from "../img/construction.gif";
import { Text, GradientBox } from "veranstalter/src/components/Design";

function WIPNotice() {
  return (
    <GradientBox gradient="soft" my={2} dir="row" alignItems="center">
      <img
        style={{ width: 42, height: 42 }}
        src={construction}
        alt="retro under construction animation"
      />
      <Text ml={2}>
        Diese Funktion ist nocht nicht fertig und funktioniert wahrscheinlich
        nicht wie erwartet
      </Text>
    </GradientBox>
  );
}

function Notice({ text }) {
  return (
    <GradientBox gradient="soft" my={2} dir="row" alignItems="center">
      <img
        style={{ width: 42, height: 42 }}
        src={construction}
        alt="retro under construction animation"
      />
      <Text ml={2}>{text}</Text>
    </GradientBox>
  );
}

export { WIPNotice, Notice };
