import React from "react";
import { Text, Flex } from "veranstalter/src/components/Design";

function StatePublishedField({ source, record = {}, label = "" }) {
  const draft = record[source] === "DRAFT";
  return (
    <Flex
      color={draft ? "util.white" : "success.main"}
      borderRadius="4px"
      py={0}
      px={2}
      bg={draft ? "warning.dark" : undefined}
      alignItems="center"
      border="2px solid"
      borderColor={draft ? "warning.dark" : "success.main"}
    >
      <Text color="inherit" bold variant="button">
        {draft ? "Entwurf" : "Live"}
      </Text>
    </Flex>
  );
}

export { StatePublishedField };
