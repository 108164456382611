import React from "react";
import {
  FaPlus,
  FaSpotify,
  FaYoutube,
  FaSoundcloud,
  FaBlind,
} from "react-icons/fa";
import {
  ChevronLeft,
  ChevronRight,
  Repeat,
  Delete,
  Clear,
  Mail,
} from "@material-ui/icons";

let iconMap = {
  add: FaPlus,
  youtube: FaYoutube,
  spotify: FaSpotify,
  soundcloud: FaSoundcloud,
  blind: FaBlind,
  back: ChevronLeft,
  forward: ChevronRight,
  delete: Delete,
  x: Clear,
  repeat: Repeat,
  mail: Mail,
};

export type IconName = keyof typeof iconMap;

function Icon({
  name,
  size = 24,
  color = "#000",
}: {
  name: IconName;
  size?: number;
  color?: string;
}) {
  const IconComponent = name && iconMap[name];
  return (
    <IconComponent
      style={{ width: size, height: size, color: color }}
      color="inherit"
    />
  );
}

export { Icon };
