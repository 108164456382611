import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Flex, Text, GradientBox } from "veranstalter/src/components/Design";
import { startOfMonth, endOfMonth } from "date-fns";
import support from "veranstalter/src/img/support.svg";
import { Button } from "veranstalter/src/components/Button";

function StatBox({ name, value }) {
  return (
    <Flex alignItems="center" mx={3}>
      <Text variant="h4" textAlign="center">
        {value}
      </Text>
      <Text variant="button" textAlign="center">
        {name}
      </Text>
    </Flex>
  );
}

function StatsBox() {
  const { data } = useQuery(
    gql`
      query VanityStats(
        $now: timestamptz
        $monthStart: timestamptz
        $monthEnd: timestamptz
      ) {
        dates: events_aggregate(where: { name: { _neq: "" } }) {
          aggregate {
            count
          }
        }
        past: events_aggregate(
          where: {
            _and: [
              { name: { _neq: "" } }
              { occurrences: { startDate: { _lt: $now } } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
        future: events_aggregate(
          where: {
            _and: [
              { name: { _neq: "" } }
              { occurrences: { startDate: { _gte: $now } } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
        month: events_aggregate(
          where: {
            _and: [
              { name: { _neq: "" } }
              { occurrences: { startDate: { _gte: $monthStart } } }
              { occurrences: { startDate: { _lte: $monthEnd } } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    {
      variables: {
        now: new Date().toUTCString(),
        monthStart: startOfMonth(new Date()).toUTCString(),
        monthEnd: endOfMonth(new Date()).toISOString(),
      },
      fetchPolicy: "cache-and-network",
    }
  );

  return (
    <GradientBox mb={3} gradient="accent">
      <Flex dir="row" justifyContent="space-between" ml={2} alignItems="center">
        <Flex color="util.white">
          <Text variant="h5" bold style={{ opacity: 0.9 }}>
            Willkommen bei KULTA
          </Text>
          <Text variant="h6" style={{ opacity: 0.9 }}>
            Hier kannst du deine Veranstaltungen verwalten
          </Text>
        </Flex>
        {data && (
          <Flex dir="row" display="flex" alignItems="center" color="util.white">
            <StatBox value={data.dates.aggregate.count} name="Insgesamt" />
            <StatBox value={data.month.aggregate.count} name="Diesen Monat" />
            <StatBox value={data.past.aggregate.count} name="Vergangen" />
          </Flex>
        )}
      </Flex>
    </GradientBox>
  );
}

function ContactBox() {
  return (
    <GradientBox gradient="accentLight">
      <Flex direction="row" alignItems="center">
        <img src={support} alt="" style={{ width: 44, height: 44 }} />
        <Text bold ml={3}>
          Feedback oder Fragen?
        </Text>
      </Flex>
      <Text my={2}>Wir freuen uns auf eine Nachricht von dir!</Text>
      <Flex style={{ opacity: 0.8 }}>
        <Button inherit={true} href="mailto:veranstalter@kulta.app" icon="mail">
          Schreib uns
        </Button>
      </Flex>
    </GradientBox>
  );
}

export { ContactBox, StatsBox, GradientBox };
