import React from "react";
import { Notification } from "react-admin";
import { AppBar } from "veranstalter/src/components/AppBar";
import { Menu } from "veranstalter/src/components/Menu";

import { useMediaQuery } from "@material-ui/core";

import { Flex } from "veranstalter/src/components/Design";
import { WithOnboardingFlow } from "veranstalter/src/resources/Profile/WithOnboardingFlow";

import { useSelector } from "react-redux";

const Layout = ({ children, logout, title }) => {
  // @ts-ignore
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const mobile = useMediaQuery("(max-width:800px)");

  return (
    <Flex minHeight="100vh" zIndex={1} position="relative">
      <Flex dir="row" flexGrow="1" pt={3} justifyContent="center">
        <Flex
          zIndex={10}
          style={
            mobile
              ? {
                  visibility: open ? "visible" : "hidden",
                  left: 0,
                  width: "100%",
                  backgroundColor: "white",
                  position: "fixed",
                  height: "90vh",
                  overflow: "scroll",
                }
              : { width: 240 }
          }
        >
          <Menu />
        </Flex>
        <Flex
          zIndex={5}
          flexGrow="2"
          style={{ overflowY: mobile ? "scroll" : "hidden" }}
          pb={3}
          mr={3}
          bg="background.default"
          px={mobile ? 1 : 0}
          width={mobile ? "100vw" : undefined}
          maxWidth={mobile ? "100vw" : 960}
        >
          <WithOnboardingFlow children={children} />
        </Flex>
      </Flex>
      <Notification />
    </Flex>
  );
};

export { Layout };
