import React, { useContext, useEffect } from "react";
import { AuthInfoContext } from "veranstalter/src/utils/AuthInfoContext";
import { VerifyEmailPrompt } from "veranstalter/src/resources/Profile/VerifyEmailPrompt";
import { useRedirect, useGetOne, Loading } from "react-admin";

function RedirectToOnboarding() {
  const redirect = useRedirect();
  useEffect(() => {
    redirect("/profiles/create");
  }, [redirect]);
  return null;
}

function WithOnboardingFlow({ children }) {
  const authInfo = useContext(AuthInfoContext);
  const userID = authInfo.sub;
  const { data: profileData, loading } = useGetOne("profiles", userID);

  const emailVerified = authInfo.email_verified;
  const hasBasicProfile = Boolean(!loading && profileData);
  const isInProfileCreate = window.location.href.includes("/profiles/create");

  if (loading) {
    // step 0: show loading indicator
    return (
      <Loading
        loadingPrimary="Prüfe Account"
        loadingSecondary="Du wirst gleich zum Login oder zu KULTA weitergeleitet"
      />
    );
  } else if (!emailVerified) {
    // step 1: needs to verify email
    return <VerifyEmailPrompt />;
  } else if (!hasBasicProfile && !isInProfileCreate) {
    // step 2: needs to create profile
    return <RedirectToOnboarding />;
  } else if (isInProfileCreate) {
    // step 3: is creating profile
    return children;
  } else {
    // step 4: is fully authenticated
    return children;
  }
}

export { WithOnboardingFlow };
