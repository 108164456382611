import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  Filter,
  TextInput,
  Pagination,
} from "react-admin";
import { CloudinaryField } from "veranstalter/src/components/CloudinaryField";
import { AgoField } from "veranstalter/src/components/DateTimeField";
import { Banners } from "veranstalter/src/components/Banners";
import { Text, Flex } from "veranstalter/src/components/Design";
import { utcToZonedTime, format } from "date-fns-tz";
import { Warning } from "@material-ui/icons";
import AddContent from "veranstalter/src/img/AddContent.svg";
import { CreateEventButton } from "veranstalter/src/components/CreateEventButton";
import { StatePublishedField } from "veranstalter/src/components/StatePublishedField";

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateEventButton secondary />
    </TopToolbar>
  );
};

const ListFilter = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput
      label="Suche"
      resettable
      source="name@_ilike,description@_ilike"
      alwaysOn
    />
  </Filter>
);

function formatForTimezone(date) {
  return format(utcToZonedTime(date, "Europe/Berlin"), "dd.MM.yyyy HH:mm", {
    timeZone: "Europe/Berlin",
  });
}

function OccurrencesField({ source, record = {} as any, label = "" }) {
  const past = record.in_past;
  const next =
    record.startDate_next && formatForTimezone(record.startDate_next);
  const last =
    record.startDate_last && formatForTimezone(record.startDate_last);
  const count = record.occurrences_count;
  if (count === 0 || (!past && !next)) {
    return (
      <Flex dir="row" alignItems="center">
        <Warning />
        <Flex ml={1}>Termin fehlt</Flex>
      </Flex>
    );
  } else if (past) {
    return <span>{`${last} (vorbei)`}</span>;
  } else if (count > 1) {
    return (
      <span>{`${next} (+${count - 1} weitere${
        count - 1 === 1 ? "r" : ""
      })`}</span>
    );
  } else {
    return <span>{next}</span>;
  }
}

function EmptyEventList() {
  return (
    <Flex alignItems="center" width="100%" pt={4} pb={6} px={4}>
      <img src={AddContent} style={{ width: "80%", maxWidth: 320 }} alt="" />
      <Flex style={{ opacity: 0.6 }} py={4} alignItems="center">
        <Text bold variant="h4" textAlign="center">
          Hier gibt es noch keine Events
        </Text>
        <Text variant="h5" my={1} textAlign="center">
          Wenn du welche erstellst, werden sie hier erscheinen.
        </Text>
      </Flex>
      <CreateEventButton />
    </Flex>
  );
}

function EventPagination(props) {
  return (
    <Pagination
      rowsPerPageOptions={[50]}
      limit={<EmptyEventList />}
      {...props}
    />
  );
}

function EventList(props) {
  return (
    <Flex mb={5}>
      <Banners />
      <Flex sx={{ overflow: ["scroll", "visible"], width: "100%" }}>
        <List
          {...props}
          sort={{ field: "updated_at", order: "DESC" }}
          empty={<EmptyEventList />}
          pagination={<EventPagination />}
          bulkActionButtons={false}
          title="Events"
          filterDefaultValues={{ in_past: false, scraped: false }}
          filters={<ListFilter />}
          perPage={25}
          actions={<ListActions />}
        >
          <Datagrid
            rowClick="edit"
            rowStyle={(record, index) => ({
              pointerEvents: record.scraped ? "none" : "auto",
              backgroundColor:
                record.statePublished === "DRAFT"
                  ? "#fdffe8"
                  : record.scraped
                  ? "#eee"
                  : undefined,
            })}
          >
            <CloudinaryField
              size={36}
              label="Bild"
              source="coverImage_handle"
              face={true}
            />
            <TextField source="name" />
            <StatePublishedField source="statePublished" label="Status" />
            <OccurrencesField source="startDate_next" label="Termine" />
            <AgoField source="updated_at" label="Bearbeitet" />
            <AgoField source="created_at" label="Erstellt" />
          </Datagrid>
        </List>
      </Flex>
    </Flex>
  );
}

export { EventList };
