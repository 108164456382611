import React from "react";

import { useTranslate, DeleteButton, SaveButton } from "react-admin";

import { useTheme, Toolbar, Link } from "@material-ui/core";
import { Flex, Text } from "veranstalter/src/components/Design";
import { useField } from "react-final-form";
import { useFormState } from "react-final-form";

function DraftToolbar({
  mutationMode = undefined,
  validating = undefined,
  isCreate = false,
  source = "state_published",
  children = (props) => <DeleteButton {...props} />,
  ...props
}) {
  const t = useTranslate();
  const th = useTheme();
  const { values } = useFormState();
  const isDraft = values[source] !== "PUBLIC";
  const forceRenderKey = String(props.pristine) + String(props.invalid);
  const {
    input: { onChange },
  } = useField(source);
  const {
    input: { onChange: changeUpdatedAt },
  } = useField("updated_at");
  return (
    <Toolbar>
      <Flex
        alignItems="center"
        direction="row"
        width="100%"
        key={forceRenderKey}
      >
        <SaveButton
          {...props}
          icon={<div />}
          label={isDraft ? t("ku.f.draft.save_publish") : t("ku.f.draft.save")}
          transform={(data) => ({
            ...data,
            updated_at: new Date().toISOString(),
            [source]: "PUBLIC",
          })}
        />
        {isDraft && (
          <Flex ml={3}>
            <SaveButton
              {...props}
              icon={<div />}
              color="inherit"
              label={t("ku.f.draft.save")}
              transform={(data) => ({
                ...data,
                updated_at: new Date().toISOString(),
                [source]: "DRAFT",
              })}
            />
          </Flex>
        )}
        <Flex dir="row" flex={1} ml={3} mr={1}>
          <Text t={isDraft ? "ku.f.draft.is_draft" : "ku.f.draft.is_public"} />
          <Flex m={1} />
          {!isDraft && (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onChange({ target: { value: "DRAFT" } });
                changeUpdatedAt({
                  target: { value: new Date().toISOString() },
                });
                props.handleSubmit();
              }}
              style={{ color: th.palette.info.dark }}
            >
              <Text color="inherit" t="ku.f.draft.convert_draft" />
            </Link>
          )}
        </Flex>
        {children(props)}
      </Flex>
    </Toolbar>
  );
}

export { DraftToolbar };
