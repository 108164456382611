import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { LinearProgress } from "react-admin";
import { Typography, Box, TextField } from "@material-ui/core";
import { ArrowForward, Delete as DeleteIcon } from "@material-ui/icons";
import {
  DateInputControlled,
  TimeField,
  DateInput,
  SplitDateTimeInputControlled,
} from "veranstalter/src/components/DateTimeInput";
import { Confirmable } from "veranstalter/src/components/Confirmable";
import { Flex, Text } from "veranstalter/src/components/Design";
import {
  addDays,
  eachDayOfInterval,
  getDay,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  isPast,
  isValid,
  isSameDay,
} from "date-fns/esm";
import { formatWithOptions } from "date-fns/esm/fp";
import de from "date-fns/locale/de";
import { useImmer } from "use-immer";
import copy from "copy-to-clipboard";
import { Touchable } from "veranstalter/src/components/Touchable";
import { Button } from "veranstalter/src/components/Button";
import MassEditPreview from "../../img/MassEditPreview.png";
import ListEditPreview from "../../img/ListEditPreview.png";
import { useField } from "react-final-form";
import { produce } from "immer";
import { Tip } from "veranstalter/src/components/Tip";
import { OptionalInput } from "veranstalter/src/components/OptionalInput";

const formatFP = formatWithOptions({ locale: de });

function dayName(offset) {
  const anArbitraryMonday = new Date(2017, 9, 23);
  return formatFP("EEEE", addDays(anArbitraryMonday, offset));
}

function OccurrenceEdit({
  showExtra,
  updateOccurrences,
  occurrence,
  isDraftEvent,
}) {
  if (occurrence.isDeleted) {
    return null;
  } else {
    const isPastEvent = isPast(new Date(occurrence.startDate));
    return (
      <Flex
        style={{ opacity: isPastEvent ? 0.7 : 1 }}
        pb={isPastEvent ? 0 : 2}
        pt={1}
      >
        <Flex dir="row">
          <Flex dir="row">
            <Flex style={{ width: 220 }}>
              <SplitDateTimeInputControlled
                value={occurrence.startDate}
                onChange={({ target: { value } }) =>
                  updateOccurrences((draft) => {
                    draft[String(occurrence.id)].startDate = value;
                  })
                }
                disabled={occurrence.cancelled}
                required
                helperText={undefined}
                label="Beginn"
                name="startDate"
              />
            </Flex>
            <Flex
              style={{ opacity: 0.5 }}
              mx={2}
              mt={4}
              onClick={() => copy(occurrence.id)}
            >
              <ArrowForward />
            </Flex>
            <OptionalInput
              initialShow={Boolean(occurrence.endDate)}
              style={{ width: 220 }}
              label="Endzeit hinzufügen"
              reset={() => {
                updateOccurrences((draft) => {
                  draft[String(occurrence.id)].endDate = null;
                });
              }}
            >
              <SplitDateTimeInputControlled
                initialDate={occurrence.startDate}
                value={occurrence.endDate}
                onChange={({ target: { value } }) =>
                  updateOccurrences((draft) => {
                    draft[String(occurrence.id)].endDate = value;
                  })
                }
                disabled={occurrence.cancelled}
                helperText={undefined}
                label="Ende"
                name="endDate"
              />
            </OptionalInput>

            {showExtra && (
              <OptionalInput
                initialShow={Boolean(occurrence.title)}
                style={{ width: 220, marginLeft: 12 }}
                label="Hinweis hinzufügen"
                reset={() => {
                  updateOccurrences((draft) => {
                    draft[String(occurrence.id)].title = null;
                  });
                }}
              >
                <TextField
                  style={{ marginTop: 8 }}
                  variant="filled"
                  name="occurrence-title"
                  value={occurrence.title}
                  onChange={({ target: { value } }) =>
                    updateOccurrences((draft) => {
                      draft[String(occurrence.id)].title = value;
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <Tip>
                        <div>{`z.B. "Q&A mit dem Autor" oder "Zusatzvorstellung"`}</div>
                      </Tip>
                    ),
                  }}
                  label="Hinweis"
                  size="small"
                  disabled={occurrence.cancelled}
                />
              </OptionalInput>
            )}
            {showExtra && (
              <Flex pt={3}>
                <Confirmable
                  skipConfirm={occurrence.isNew || isDraftEvent}
                  onConfirm={() =>
                    updateOccurrences((draft) => {
                      draft[String(occurrence.id)].isDeleted = true;
                    })
                  }
                  icon={<DeleteIcon />}
                  red={true}
                  title="Termin Löschen"
                >
                  <Text>
                    Wenn du einen Fehler gemacht hast, kannst du den Termin
                    löschen. Andernfalls solltest du ihn lieber absagen.
                  </Text>
                  <Text mt={2}>
                    Erst wenn du das ganze Event speicherst, wird der Termin
                    tatsächlich gelöscht.
                  </Text>
                </Confirmable>
              </Flex>
            )}
          </Flex>
          {!isDraftEvent && !occurrence.isNew && (
            <Flex pt={occurrence.cancelled ? 2 : 3} px={3} alignItems="center">
              {occurrence.cancelled && <Text>Termin abgesagt</Text>}
              <Confirmable
                link={occurrence.cancelled}
                label={occurrence.cancelled ? "wiedereinstellen" : "Absagen"}
                title={
                  occurrence.cancelled
                    ? "Termin wiedereinstellen"
                    : "Termin absagen"
                }
                onConfirm={() =>
                  updateOccurrences((draft) => {
                    draft[String(occurrence.id)].cancelled =
                      !occurrence.cancelled;
                  })
                }
              >
                {occurrence.cancelled ? (
                  <Text>
                    Dieser Termin ist abgesagt. Du kannst ihn wiedereinstellen,
                    falls er doch stattfindent.
                  </Text>
                ) : (
                  <>
                    <Text>
                      Wenn du den Termin absagst, werden InteressentInnen die
                      ihn schon gespeichtert haben darüber informiert.
                    </Text>
                    <Text mt={2}>
                      Erst wenn du das ganze Event speicherst, wird der Termin
                      tatsächlich abgesagt.
                    </Text>
                    <Flex mt={3}>
                      <TextField
                        variant="filled"
                        multiline
                        rows={2}
                        name="reason"
                        value={occurrence.data?.reason || ""}
                        onChange={({ target: { value } }) =>
                          updateOccurrences((draft) => {
                            draft[String(occurrence.id)].data = {
                              ...draft[String(occurrence.id)].data,
                              reason: value,
                            };
                          })
                        }
                        label="Grund der Absage"
                        size="small"
                      />
                    </Flex>
                  </>
                )}
              </Confirmable>
            </Flex>
          )}
        </Flex>
        {isPastEvent && (
          <Text bold variant="caption">
            Hinweis: Der Startzeitpunkt befindet sich in der Vergangenheit
          </Text>
        )}
      </Flex>
    );
  }
}

function makeID() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

function RangeInputRow({ offset, range, updateRanges }) {
  return (
    <Flex dir="row" mb={2}>
      <TimeField
        name="startTime"
        value={range.startTime}
        onChange={({ target: { value } }) => {
          updateRanges((draft) => {
            draft[offset][range.id].startTime = value;
          });
        }}
        style={{ width: 100 }}
        label="Beginn"
        helperText={undefined}
      />
      <Flex mx={2} mt={4}>
        <ArrowForward />
      </Flex>
      <TimeField
        name="endTime"
        value={range.endTime}
        onChange={({ target: { value } }) => {
          updateRanges((draft) => {
            draft[offset][range.id].endTime = value;
          });
        }}
        style={{ width: 100 }}
        label="Ende"
        helperText={undefined}
      />
      <Flex mt={3} justifyContent="center">
        <Button
          secondary
          icon="x"
          onClick={() =>
            updateRanges((draft) => {
              delete draft[offset][range.id];
            })
          }
        />
      </Flex>
    </Flex>
  );
}

function AddRangeButton({ onClick, label = "Zeit", secondary = false }) {
  return (
    <Box mr={3} style={{ opacity: secondary ? 0.6 : 1 }}>
      <Button onClick={onClick} secondary={true} icon="add" children={label} />
    </Box>
  );
}

function simpleDateToTime(date) {
  return formatFP("HH:mm", date);
}

function mashDateTime(date: Date, time: Date) {
  return setMinutes(setHours(date, getHours(time)), getMinutes(time));
}

function validateArtDates({ start, end, ranges }) {
  if (!start && !end && !ranges) {
    return false;
  }
  try {
    if (!isValid(new Date(start))) {
      return false;
    }
    if (!isValid(new Date(end))) {
      return false;
    }
  } catch (e) {
    return false;
  }
  try {
    if (JSON.parse(ranges).length !== 7) {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
}

function artDatesToOccurrences({ start, end, ranges, exception }) {
  let occurrences = [];
  const exs = exception ? Object.values(JSON.parse(exception)) : [];
  const days = eachDayOfInterval({
    start: new Date(start),
    end: new Date(end),
  });
  days.forEach((day) => {
    const isCancelled = Boolean(
      exs.find(
        ({ date, type }) =>
          isSameDay(new Date(date), day) && type === "cancellation"
      )
    );
    if (!isCancelled) {
      const offset = getDay(day);
      const realOffset = offset === 0 ? 6 : offset - 1;
      Object.values(JSON.parse(ranges)[realOffset]).forEach((range: any) => {
        if (
          isValid(new Date(range.startTime)) &&
          isValid(new Date(range.endTime))
        ) {
          occurrences.push({
            startDate: mashDateTime(new Date(day), new Date(range.startTime)),
            endDate: mashDateTime(new Date(day), new Date(range.endTime)),
          });
        }
      });
    }
  });
  exs
    .filter(({ type }) => type === "addition")
    .forEach(({ date, start, end }) => {
      occurrences.push({
        startDate: mashDateTime(new Date(date), new Date(start)),
        endDate: mashDateTime(new Date(date), new Date(end)),
      });
    });
  return occurrences;
}

function useImmerField({ source, initial }) {
  const {
    input: { onChange: ffOnChange, value: ffValue },
  } = useField(source);
  const onChangeRef = useRef(ffOnChange);

  useEffect(() => {
    onChangeRef.current = ffOnChange;
  }, [ffOnChange]);

  const value = useMemo(
    () => (ffValue ? JSON.parse(ffValue) : initial),
    [ffValue, initial]
  );

  const updateValue = useCallback(
    (recipe) => {
      const newVal = produce(value, recipe);
      onChangeRef.current(JSON.stringify(newVal));
    },
    [value]
  );

  return [value, updateValue];
}

function ExceptionsInput({ source }) {
  const [exceptions, updateExceptions] = useImmerField({ source, initial: {} });
  return (
    <Flex>
      <Flex bg="#eee" mt={3}>
        <Flex direction="row" alignItems="center" px={3} p={2} bg="#ddd">
          <Text bold mr={3} flex={1}>
            Abweichungen
          </Text>
          <AddRangeButton
            label="Ausfall"
            onClick={() => {
              updateExceptions((draft) => {
                const id = makeID().toString();
                draft[id] = {
                  id,
                  type: "cancellation",
                  date: null,
                };
              });
            }}
          />
          <AddRangeButton
            label="Zusatztermin"
            onClick={() => {
              updateExceptions((draft) => {
                const id = makeID().toString();
                draft[id] = {
                  id,
                  type: "addition",
                  date: null,
                  start: null,
                  end: null,
                };
              });
            }}
          />
        </Flex>
        {Object.values(exceptions).map((ex: any) => (
          <Flex direction="row" alignItems="center" key={ex.id}>
            <Flex flex={2} direction="row" alignItems="center">
              <Button
                secondary
                icon="x"
                onClick={() =>
                  updateExceptions((draft) => {
                    delete draft[ex.id];
                  })
                }
              />
              <Text bold mr={3}>
                {ex.type === "cancellation" ? "Ausfall" : "Zusatztermin"}
              </Text>
            </Flex>
            <Flex flex={4} direction="row" alignItems="center">
              <DateInputControlled
                style={{ width: 180 }}
                value={ex.date}
                onChange={({ target: { value } }) =>
                  updateExceptions((draft) => {
                    draft[ex.id].date = value;
                  })
                }
                label="Tag"
                name="day"
              />
              {ex.type === "addition" && (
                <Flex direction="row" alignItems="center" ml={3}>
                  <TimeField
                    name="startTime"
                    value={ex.start}
                    onChange={({ target: { value } }) => {
                      updateExceptions((draft) => {
                        draft[ex.id].start = value;
                      });
                    }}
                    style={{ width: 80 }}
                    label="Beginn"
                  />
                  <Flex mx={2}>
                    <ArrowForward />
                  </Flex>
                  <TimeField
                    name="endTime"
                    value={ex.end}
                    onChange={({ target: { value } }) => {
                      updateExceptions((draft) => {
                        draft[ex.id].end = value;
                      });
                    }}
                    style={{ width: 80 }}
                    label="Ende"
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

function OpeningHoursInput({ source }) {
  const [ranges, updateRanges] = useImmerField({
    source,
    initial: [{}, {}, {}, {}, {}, {}, {}],
  });

  return (
    <Flex>
      {[0, 1, 2, 3, 4, 5, 6].map((offset) => (
        <Flex
          dir="row"
          alignItems="center"
          key={offset}
          py={2}
          px={3}
          style={{
            backgroundColor: offset % 2 === 1 ? "#f2f2f2" : "transparent",
          }}
        >
          <Flex flex="2">
            <Text bold>{dayName(offset)}</Text>
          </Flex>
          <Flex flex="6" pt={1}>
            {Object.values(ranges[offset]).map(
              (range: { startTime: any; endTime: any; id: string }) => (
                <RangeInputRow
                  key={range.id}
                  range={range}
                  offset={offset}
                  updateRanges={updateRanges}
                />
              )
            )}
            <AddRangeButton
              label={
                Object.keys(ranges[offset]).length > 0
                  ? "Weitere Öffnungszeit"
                  : "Öffnungszeit hinzufügen"
              }
              onClick={() =>
                updateRanges((draft) => {
                  const newID = makeID();
                  draft[offset][newID] = {
                    startTime: "",
                    endTime: "",
                    id: newID,
                  };
                })
              }
            />
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

function MassOccurrenceEdit2() {
  return (
    <Flex my={3} pb={5}>
      <Flex dir="row">
        <DateInput
          source="data.artDates.start"
          label="Erster Tag"
          name="start"
        />
        <Flex mx={2} mt={4}>
          <ArrowForward />
        </Flex>
        <DateInput source="data.artDates.end" label="Letzter Tag" name="end" />
      </Flex>
      <OpeningHoursInput source="data.artDates.ranges" />
      <ExceptionsInput source="data.artDates.exception" />
    </Flex>
  );
}

function makeNewOccurrenceID() {
  const min = 100000000;
  return Math.round(
    Math.abs(Math.random() * (Number.MAX_SAFE_INTEGER - min) + min)
  );
}

function MassOccurrenceEdit({ occurrences, updateOccurrences, onCreated }) {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [ranges, updateRanges] = useImmer([{}, {}, {}, {}, {}, {}, {}]);
  const _first = ranges.find((o) => Object.keys(o).length > 0);
  const first =
    _first &&
    Object.values(_first).filter(
      (o: { startTime: any; endTime: any }) => o.startTime && o.endTime
    );

  const createDates = useCallback(() => {
    updateOccurrences((draft) => {
      Object.keys(draft).forEach((key) => {
        draft[key].isDeleted = true;
      });
      const days = eachDayOfInterval({ start, end });
      days.forEach((day) => {
        const offset = getDay(day);
        const realOffset = offset === 0 ? 6 : offset - 1;
        Object.values(ranges[realOffset]).forEach((range: any) => {
          const newID = makeNewOccurrenceID();
          draft[String(newID)] = {
            isNew: true,
            isDeleted: false,
            id: newID,
            startDate: mashDateTime(day, range.startTime),
            endDate: mashDateTime(day, range.endTime),
          };
        });
      });
    });
  }, [end, ranges, start, updateOccurrences]);
  return (
    <Box my={3}>
      <Flex dir="row">
        <DateInputControlled
          value={start}
          onChange={({ target: { value } }) => setStart(value)}
          label="Erster Tag"
          name="start"
        />
        <Flex mx={2} mt={4}>
          <ArrowForward />
        </Flex>
        <DateInputControlled
          value={end}
          onChange={({ target: { value } }) => setEnd(value)}
          label="Letzer Tag"
          name="end"
        />
      </Flex>
      <Flex>
        {[0, 1, 2, 3, 4, 5, 6].map((offset) => (
          <Flex
            dir="row"
            key={offset}
            py={2}
            px={3}
            style={{
              backgroundColor: offset % 2 === 1 ? "#f2f2f2" : "transparent",
            }}
          >
            <Flex flex="1" mt={1}>
              <Text>{dayName(offset)}</Text>
            </Flex>
            <Flex flex="6">
              {Object.values(ranges[offset]).map(
                (range: { startTime: any; endTime: any; id: string }) => (
                  <RangeInputRow
                    key={range.id}
                    range={range}
                    offset={offset}
                    updateRanges={updateRanges}
                  />
                )
              )}
              <Flex dir="row">
                <AddRangeButton
                  label={
                    Object.keys(ranges[offset]).length > 0
                      ? "Weitere Öffnungszeit"
                      : "Öffnungszeit hinzufügen"
                  }
                  onClick={() =>
                    updateRanges((draft) => {
                      const newID = makeID();
                      draft[offset][newID] = {
                        startTime: "",
                        endTime: "",
                        id: newID,
                      };
                    })
                  }
                />
                {Object.keys(ranges[offset]).length === 0 &&
                  first &&
                  first.length > 0 && (
                    <AddRangeButton
                      secondary={true}
                      label={
                        first
                          .map(
                            (range: { startTime: any; endTime: any }) =>
                              `${simpleDateToTime(
                                range.startTime
                              )} - ${simpleDateToTime(range.endTime)}`
                          )
                          .join(` und  `) + ` hinzufügen`
                      }
                      onClick={() =>
                        updateRanges((draft) => {
                          first.forEach(
                            (range: { startTime: any; endTime: any }) => {
                              const newID = makeID();
                              draft[offset][newID] = {
                                startTime: range.startTime,
                                endTime: range.endTime,
                                id: newID,
                              };
                            }
                          );
                        })
                      }
                    />
                  )}
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Text variant="subtitle1">
        Für diesen Zeitraum werden wir jetzt die konkreten Termine erstellen,
        die du danach weiter bearbeiten kannst.
      </Text>
      {Object.values(occurrences).length > 1 && (
        <Text variant="subtitle2" color="error">
          {`Achtung: ${
            Object.values(occurrences).length
          } bestehende Termine werden überschrieben, wenn du auf "TERMINE NEU ERSTELLEN" klickst.`}
        </Text>
      )}
      <Box mt={3}>
        <Button
          icon="forward"
          onClick={() => {
            createDates();
            onCreated();
          }}
        >
          {Object.values(occurrences).length > 1
            ? "Termine neu erstellen"
            : "Termine Erstellen"}
        </Button>
      </Box>
    </Box>
  );
}

interface EditListProps {
  occurrences: any[];
  updateOccurrences: (draft: any) => void;
  isDraftEvent?: boolean;
  showExtra?: boolean;
}

function OccurrenceListEdit({
  occurrences,
  updateOccurrences,
  isDraftEvent,
  showExtra,
}: EditListProps) {
  const sorted = Object.values(occurrences).sort((a, b) => a.order - b.order);
  if (sorted.length === 0) {
    return (
      <Flex my={8}>
        <LinearProgress />
      </Flex>
    );
  }
  return (
    <div>
      <Box>
        {sorted.map((occurrence) => (
          <OccurrenceEdit
            showExtra={showExtra}
            key={occurrence.id}
            occurrence={occurrence}
            updateOccurrences={updateOccurrences}
            isDraftEvent={isDraftEvent}
          />
        ))}
      </Box>
      <Box mb={3} mt={1}>
        <Button
          secondary
          onClick={(e) => {
            updateOccurrences((draft) => {
              const newID = makeNewOccurrenceID();
              draft[String(newID)] = {
                isNew: true,
                isDeleted: false,
                order: Number.MAX_SAFE_INTEGER - 1000,
                id: newID,
              };
            });
          }}
          icon="add"
          children="Weiteren Termin Hinzufügen"
        />
      </Box>
    </div>
  );
}

function Header({ notDeleted }) {
  return (
    <Typography variant="h6">
      {notDeleted && notDeleted.length > 1
        ? `Termine (${notDeleted.length})`
        : "Termin"}
    </Typography>
  );
}

function ArtHeader() {
  return <Typography variant="h6">Öffnungszeiten und Tage</Typography>;
}

function OptionTile({
  onPress = () => {},
  icon = undefined,
  title,
  description = "",
}) {
  return (
    <Touchable
      feedbackStyle="highlight"
      onPress={onPress}
      style={{ flex: 1, borderRadius: 8, maxWidth: 500 }}
    >
      <Flex dir="row" p={3} alignItems="center">
        {icon && (
          <img
            style={{ width: 80, height: 80, marginTop: 8 }}
            src={icon}
            alt=""
          />
        )}
        <Flex dir="column" ml={2}>
          <Text variant="h6">{title}</Text>
          <Text variant="subtitle2">{description}</Text>
        </Flex>
      </Flex>
    </Touchable>
  );
}

// eslint-disable-next-line
function MassEdit({
  occurrences,
  updateOccurrences,
  showExtra,
  isDraftEvent,
}: EditListProps) {
  const [currentView, setCurrentView] = useState<
    "intro" | "list" | "create" | "initial"
  >("initial");

  useEffect(() => {
    if (currentView === "initial") {
      let all = Object.values(occurrences);
      if (all.length === 1 && all[0] && !all[0].startDate) {
        setCurrentView("intro");
      } else if (all.length === 0) {
      } else {
        setCurrentView("list");
      }
    }
  }, [currentView, occurrences]);

  switch (currentView) {
    case "intro": {
      return (
        <Flex>
          <Text variant="subtitle1">
            Für Ausstellungen und andere Kunstveranstaltungen bieten wir zwei
            verschiene Wege, wie du Termine angeben kannst:
          </Text>
          <Flex dir="row" py={2}>
            <OptionTile
              icon={MassEditPreview}
              onPress={() => setCurrentView("create")}
              title="Längere Ausstellung"
              description="Du kannst zuerst die Dauer der Ausstellung und die Öffnungszeiten angeben und danach Sonderfälle bearbeiten."
            />
            <OptionTile
              icon={ListEditPreview}
              onPress={() => setCurrentView("list")}
              title="Einzelne Termine"
              description="Du kannst jeden Termin einzeln erstellen und bearbeiten, so wie du es vielleicht schon von anderen Veranstaltungen kennst."
            />
          </Flex>
        </Flex>
      );
    }
    case "create": {
      return (
        <Flex alignItems="flex-start" mb={3}>
          <Flex direction="column" alignItems="flex-start">
            <Button
              secondary
              onClick={() => setCurrentView("intro")}
              icon="back"
            >
              Zurück
            </Button>
            <Text variant="subtitle1">
              Gib bitte zuerst die Laufzeit der Ausstellung und die üblichen
              Öffnungszeiten an. Im nächsten Schritt bekommst du dann die
              Möglichkeit, Sonderfälle zu bearbeiten.
            </Text>
          </Flex>
          <MassOccurrenceEdit
            occurrences={occurrences}
            updateOccurrences={updateOccurrences}
            onCreated={() => setCurrentView("list")}
          />
        </Flex>
      );
    }
    case "list": {
      return (
        <Flex alignItems="flex-start" mb={3}>
          <OccurrenceListEdit
            isDraftEvent={isDraftEvent}
            showExtra={showExtra}
            occurrences={occurrences}
            updateOccurrences={updateOccurrences}
          />
          <Button
            onClick={() => setCurrentView("create")}
            secondary
            icon="repeat"
          >
            Termine für längere Austellung neu erstellen
          </Button>
        </Flex>
      );
    }
    case "initial": {
      return null;
    }
  }
}

function OccurrencesInput({
  occurrences,
  updateOccurrences,
  eventID,
  isDraftEvent,
  withMassEdit = false,
}) {
  const notDeleted =
    occurrences &&
    Object.values(occurrences).filter(({ isDeleted }) => !isDeleted);
  if (!occurrences) {
    return <LinearProgress />;
  } else if (!withMassEdit) {
    return (
      <Flex>
        <Header notDeleted={notDeleted} />
        <Flex sx={{ overflow: ["scroll", "visible"] }}>
          <OccurrenceListEdit
            isDraftEvent={isDraftEvent}
            showExtra={notDeleted.length > 1}
            occurrences={occurrences}
            updateOccurrences={updateOccurrences}
          />
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex>
        <ArtHeader />
        <Flex direction="row">
          <Flex flex={2} mr={3}>
            <MassOccurrenceEdit2 />
          </Flex>
          <Flex flex={1} />
        </Flex>
      </Flex>
    );
  }
}

export {
  OccurrencesInput,
  artDatesToOccurrences,
  validateArtDates,
  makeNewOccurrenceID,
};
