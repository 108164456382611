import React, { useState, useCallback, createContext } from "react";
import { useField } from "react-final-form";
import { Box } from "@material-ui/core";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { Flex } from "veranstalter/src/components/Design";
import { getHours, getMinutes, setHours, setMinutes } from "date-fns/esm";

const PickerContext = createContext((s = false) => {});

function DateTimeInput({ source, label, ...rest }) {
  const {
    input: { onChange, value },
    meta: { touched, error },
  } = useField(source);
  return (
    <DateTimeInputControlled
      name={source}
      label={label}
      onChange={onChange}
      value={value}
      touched={touched}
      error={error}
      {...rest}
    />
  );
}

function mashDateTime(date: Date, time: Date) {
  return setMinutes(setHours(date, getHours(time)), getMinutes(time));
}

const SplitDateTimeInputControlled = ({
  name,
  label,
  onChange,
  value,
  touched = false,
  error = "",
  initialDate = undefined,
  ...rest
}) => {
  const [dateInt, setDateInt] = useState(
    value && utcToZonedTime(value, "Europe/Berlin").toISOString()
  );
  const [timeInt, setTimeInt] = useState(
    value && utcToZonedTime(value, "Europe/Berlin").toISOString()
  );

  const tryValueUpdate = useCallback(
    (withDate, withTime) => {
      if (!withDate || !withTime) {
        return false;
      }
      try {
        let newVal = mashDateTime(new Date(withDate), new Date(withTime));
        const newTime = zonedTimeToUtc(newVal, "Europe/Berlin");
        newTime.toISOString();

        if (newTime) {
          onChange({ target: { value: newTime.toISOString() } });
        }
      } catch (e) {
        return false;
      }
    },
    [onChange]
  );

  const onOnlyDateChange = useCallback(
    (date) => {
      let utcTime = null;
      try {
        const newTime = zonedTimeToUtc(date, "Europe/Berlin");
        newTime.toISOString();
        utcTime = newTime;
      } catch (e) {
        // not a complete entry
      }
      if (utcTime) {
        setDateInt(utcTime.toISOString());
        tryValueUpdate(utcTime.toISOString(), timeInt);
      }
    },
    [timeInt, tryValueUpdate]
  );

  const onOnlyTimeChange = useCallback(
    (date) => {
      let utcTime = null;
      try {
        const newTime = zonedTimeToUtc(date, "Europe/Berlin");
        newTime.toISOString();
        utcTime = newTime;
      } catch (e) {
        // not a complete entry
      }
      if (utcTime) {
        setTimeInt(utcTime.toISOString());
        tryValueUpdate(dateInt, utcTime.toISOString());
      }
    },
    [dateInt, tryValueUpdate]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Flex mt={2} direction="row">
        <Flex flex={4}>
          <KeyboardDatePicker
            InputAdornmentProps={{
              position: "start",
              style: {
                opacity: 0.8,
                marginLeft: 8,
                marginRight: 22,
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
            InputProps={{ style: { padding: 0, margin: 0 } }}
            KeyboardButtonProps={{ style: { margin: 0, padding: 0 } }}
            disableToolbar={true}
            initialFocusedDate={initialDate}
            minDate={initialDate}
            variant="inline"
            inputVariant="filled"
            size="small"
            autoOk={true}
            name={name}
            // @ts-ignore
            ampm={false}
            label={label + ` - Tag`}
            value={dateInt || null}
            onChange={onOnlyDateChange}
            error={!!(touched && error)}
            helperText={touched && error ? error : " "}
            format="dd.MM.yyyy"
            placeholder="__.__.____"
            InputLabelProps={{
              shrink: true,
            }}
            {...rest}
          />
        </Flex>
        <Flex flex={2}>
          <KeyboardTimePicker
            KeyboardButtonProps={{
              disabled: true,
              style: { display: "none" },
            }}
            variant="dialog"
            inputVariant="filled"
            size="small"
            name={name}
            ampm={false}
            label="Zeit"
            value={timeInt || null}
            onChange={onOnlyTimeChange}
            format="HH:mm"
            placeholder="__:__"
            InputLabelProps={{
              shrink: true,
            }}
            {...rest}
          />
        </Flex>
      </Flex>
    </MuiPickersUtilsProvider>
  );
};

function DateInput({ source, label, ...rest }) {
  const {
    input: { onChange, value },
    meta: { touched, error },
  } = useField(source);
  return (
    <DateInputControlled
      name={source}
      label={label}
      onChange={onChange}
      value={value}
      touched={touched}
      error={error}
      {...rest}
    />
  );
}

function TimeInput({ source, label, ...rest }) {
  const {
    input: { onChange, value },
    meta: { touched, error },
  } = useField(source);
  return (
    <TimeField
      name={source}
      label={label}
      onChange={onChange}
      value={value}
      touched={touched}
      error={error}
      {...rest}
    />
  );
}

function DateInputControlled({
  name,
  label,
  onChange,
  value,
  touched = false,
  error = "",
  initialDate = undefined,
  ...rest
}) {
  const onDateChange = useCallback(
    (date) => {
      if (String(new Date(date)) !== "Invalid Date") {
        onChange({ target: { value: date } });
      }
    },
    [onChange]
  );

  return (
    <Box mt={2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar={true}
          initialFocusedDate={initialDate}
          minDate={initialDate}
          variant="inline"
          inputVariant="filled"
          size="small"
          autoOk={true}
          name={name}
          // @ts-ignore
          ampm={false}
          label={label}
          value={value || null}
          onChange={onDateChange}
          error={!!(touched && error)}
          helperText={touched && error ? error : " "}
          format="dd.MM.yyyy"
          placeholder="__.__.____"
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
}

function TimeField({
  name,
  label,
  onChange,
  value,
  touched = false,
  error = "",
  ...rest
}) {
  const onDateChange = useCallback(
    (date) => {
      if (String(new Date(date)) !== "Invalid Date") {
        onChange({ target: { value: date } });
      }
    },
    [onChange]
  );
  return (
    <Box mt={2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          KeyboardButtonProps={{
            disabled: true,
            style: { display: "none" },
          }}
          variant="dialog"
          inputVariant="filled"
          size="small"
          name={name}
          ampm={false}
          label={label}
          format="HH:mm"
          placeholder="__:__"
          value={value || null}
          onChange={onDateChange}
          error={!!(touched && error)}
          helperText={touched && error ? error : " "}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
}

const DateTimeInputControlled = ({
  name,
  label,
  onChange,
  value,
  touched = false,
  error = "",
  initialDate = undefined,
  ...rest
}) => {
  const onDateChange = useCallback(
    (date) => {
      let utcTime = null;
      try {
        const newTime = zonedTimeToUtc(date, "Europe/Berlin");
        newTime.toISOString();
        utcTime = newTime;
      } catch (e) {
        // not a complete entry
      }
      if (utcTime) {
        onChange({ target: { value: utcTime.toISOString() } });
      }
    },
    [onChange]
  );
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box mt={2}>
      <PickerContext.Provider value={setIsOpen}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            initialFocusedDate={initialDate}
            minDate={initialDate}
            variant="inline"
            inputVariant="filled"
            size="small"
            name={name}
            ampm={false}
            label={label}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            value={value ? utcToZonedTime(value, "Europe/Berlin") : null}
            onChange={(value) => {
              onDateChange(value);
            }}
            hideTabs={true}
            disableToolbar={false}
            error={!!(touched && error)}
            helperText={touched && error ? error : " "}
            format="dd.MM.yyyy HH:mm"
            placeholder="__.__.____ __:__"
            InputLabelProps={{
              shrink: true,
            }}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      </PickerContext.Provider>
    </Box>
  );
};

export {
  DateTimeInput,
  DateInputControlled,
  DateTimeInputControlled,
  TimeField,
  DateInput,
  TimeInput,
  SplitDateTimeInputControlled,
};
