import React from "react";
import { Add } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { useRedirect } from "react-admin";

function CreateEventButton({ label = "Neues Event", secondary = false }) {
  const redirect = useRedirect();
  return (
    <Button
      onClick={() => redirect(`/events/create`)}
      size={!secondary ? "large" : "medium"}
      variant={!secondary ? "contained" : undefined}
      color={!secondary ? "secondary" : "primary"}
      startIcon={<Add />}
    >
      {label}
    </Button>
  );
}

function ImportButton() {
  return null;
}

export { CreateEventButton, ImportButton };
