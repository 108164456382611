import React, { FC, ComponentProps } from "react";
import { Create, SaveButton, Toolbar } from "react-admin";
import {
  Typography,
  Box,
  Toolbar as MUIToolbar,
  Button as MUIButton,
  Grid,
} from "@material-ui/core";
import { ProfileFieldsEssential, ProfileFieldsDetails } from "./ProfileEdit";
import { Required } from "veranstalter/src/components/Required";
import { WizardForm, WizardFormStep } from "@react-admin/ra-form-layout";
import { Flex, Text } from "veranstalter/src/components/Design";
import { ChevronLeft, ChevronRight, Check } from "@material-ui/icons";
import Welcome from "veranstalter/src/img/Welcome.svg";

type BaseToolbarSubmitProps = Pick<
  ComponentProps<typeof Toolbar>,
  | "handleSubmit"
  | "handleSubmitWithRedirect"
  | "invalid"
  | "redirect"
  | "saving"
  | "submitOnEnter"
>;

export type WizardToolbarProps = {
  hasPreviousStep?: boolean;
  hasNextStep?: boolean;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
} & BaseToolbarSubmitProps;

const noop = (): void => null;

/**
 * The Toolbar displayed at the bottom of WizardForm.
 *
 * @prop {boolean} hasPreviousStep Optionnal. Does the wizard have a previous step?
 * @prop {boolean} hasNextStep Optionnal. Does the wizard have a next step?
 * @prop {Function} onPreviousClick Optionnal. Previous button click action
 * @prop {Function} onNextClick Optionnal. Next button click action
 * @prop {...BaseToolbarSubmitProps}
 */
const WizardToolbar: FC<WizardToolbarProps> = ({
  hasPreviousStep = false,
  hasNextStep = false,
  onPreviousClick = noop,
  onNextClick = noop,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  redirect,
  saving,
  submitOnEnter,
}) => {
  return (
    <MUIToolbar>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          {hasPreviousStep ? (
            <MUIButton
              size="large"
              startIcon={<ChevronLeft />}
              variant="text"
              onClick={onPreviousClick}
            >
              {"Zurück"}
            </MUIButton>
          ) : null}
        </Grid>
        <Grid item>
          {hasNextStep && hasPreviousStep ? (
            <MUIButton
              disabled={invalid}
              variant="text"
              size="large"
              onClick={onNextClick}
              style={{ marginRight: 12 }}
            >
              <Text variant="button" key={String(invalid)}>
                {"Überspringen"}
              </Text>
            </MUIButton>
          ) : null}
          {hasNextStep ? (
            <MUIButton
              startIcon={<ChevronRight />}
              disabled={invalid}
              variant="contained"
              color="secondary"
              size="large"
              onClick={onNextClick}
            >
              <Text variant="button" key={String(invalid)}>
                {"Weiter"}
              </Text>
            </MUIButton>
          ) : (
            <SaveButton
              color="secondary"
              label="Fertig"
              icon={<Check />}
              size="large"
              handleSubmitWithRedirect={
                handleSubmitWithRedirect || handleSubmit
              }
              disabled={invalid}
              invalid={invalid}
              redirect={redirect}
              saving={saving}
              submitOnEnter={submitOnEnter}
            />
          )}
        </Grid>
      </Grid>
    </MUIToolbar>
  );
};

function ProfileCreate({ resource = "profiles", basePath = "#" }) {
  return (
    <Box maxWidth="600px">
      <Box m={3}>
        <Typography variant="h5">
          Wilkommen bei KULTA - Schön, dass du dabei bist!
        </Typography>
        <Typography>
          Fülle bitte zuerst dein Veranstalterprofil aus. Du kannst dein Profil
          jederzeit in deinem Nutzerkonto bearbeiten.
        </Typography>
      </Box>
      <Create resource={resource} basePath={basePath} title="Profil">
        <WizardForm redirect="/" toolbar={WizardToolbar}>
          <WizardFormStep label="Profil">
            <ProfileFieldsEssential />
          </WizardFormStep>
          <WizardFormStep label="Details">
            {/* @ts-ignore */}
            <Flex direction="row" fullWidth mb={3} alignItems="center">
              <Text>
                Ergänze dein Profil, um BesucherInnen mehr Infos über dich zu
                geben. Du kannst diese Infos auch später ausfüllen.
              </Text>
            </Flex>
            <ProfileFieldsDetails />
          </WizardFormStep>
          <WizardFormStep label="Fertig!">
            {/* @ts-ignore */}
            <Flex mx={3} mt={3} mb={5} fullWidth alignItems="center">
              <Flex mb={5}>
                <img src={Welcome} style={{ width: 150 }} alt="" />
              </Flex>
              <Text variant="h5" mb={1}>
                Los geht’s!
              </Text>
              <Text style={{ textAlign: "center" }}>
                Du kannst jetzt deine ersten Veranstaltungen erstellen.
              </Text>
              <Text style={{ textAlign: "center" }}>
                Sobald wir deinen Account bestätigt haben, werden deine
                Veranstaltungen dann auch live in der KULTA App erscheinen 👋
              </Text>
            </Flex>
          </WizardFormStep>
        </WizardForm>
      </Create>
      <Required />
    </Box>
  );
}

export { ProfileCreate };
