function createAuthProvider(auth0) {
  return {
    login: (params) => Promise.resolve(),
    logout: (params) => auth0.logout({ returnTo: window.location.origin }),
    checkAuth: (params) => Promise.resolve(),
    checkError: (error) => Promise.resolve(),
    getPermissions: (params) => Promise.resolve(),
  };
}

export { createAuthProvider };
