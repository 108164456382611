import React, { useEffect, useState } from "react";
import { Labeled, useInput, LinearProgress } from "react-admin";
import { Flex, Text } from "veranstalter/src/components/Design";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Loader } from "@googlemaps/js-api-loader";
import { Link, useTheme } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";

const MAPS_API_KEY = "AIzaSyDLrGdoBswTEAQJ2OyRAQ0_r2PvEG8vueM";

function WithGoogleMapsLoaded({ Component, ...props }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hadError, setHadError] = useState(false);
  useEffect(() => {
    const loader = new Loader({
      apiKey: MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });
    loader
      .load()
      .then(() => {
        setIsLoaded(true);
      })
      .catch((e) => {
        setHadError(true);
      });
  }, []);
  if (hadError) {
    return <Text>Es gibt ein Problem mit der Google Maps Anbindung</Text>;
  } else if (!isLoaded) {
    return <LinearProgress />;
  } else {
    return <Component {...props} />;
  }
}

function AddressInput(props) {
  return (
    <WithGoogleMapsLoaded Component={AddressInputWithMapsLoaded} {...props} />
  );
}

const AddressInputWithMapsLoaded = ({ main, ...props }) => {
  const label = props.label;
  const th = useTheme();
  const {
    input: { onChange, value, onBlur, onFocus },
    meta: { touched, error },
    isRequired,
  } = useInput(props as unknown as any);
  const [internal, setInternal] = useState(null);
  const [didChangeOnce, setDidChangeOnce] = useState(false);
  const [isPopularSuggestion, setIsPopularSuggestion] = useState(false);
  useEffect(() => {
    if (value.length === 0 && main && !didChangeOnce) {
      setIsPopularSuggestion(true);
      onChange({ target: { value: main } });
    }
  }, [value, main, onChange, didChangeOnce]);
  const [showEdit, setShowEdit] = useState(value.length === 0 && !main);

  useEffect(() => {
    if (value.length > 0) {
      var service = new window.google.maps.places.PlacesService(
        document.getElementById("maps-attribution") as HTMLDivElement
      );
      service.getDetails({ placeId: value }, function (place, status) {
        setInternal({
          label: place.name || place.formatted_address,
          value: { place_id: value, reference: value },
        });
      });
    } else {
      setInternal(null);
    }
  }, [value]);
  return (
    <Flex>
      {!showEdit && (
        <Flex mt={2} ml={2}>
          <Flex direction="row" sx={{ marginTop: 3, alignItems: "baseline" }}>
            <Text variant="caption" sx={{ color: "#666" }}>
              {value === main && isPopularSuggestion
                ? "Ort (meistens verwendet)"
                : "Ort"}
            </Text>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setDidChangeOnce(true);
                setShowEdit(true);
                setInternal(null);
                onChange({ target: { value: null } });
              }}
              style={{ color: th.palette.info.dark }}
            >
              <Text
                ml={1}
                sx={{ fontWeight: "bold" }}
                variant="caption"
                color="inherit"
              >
                <EditIcon
                  style={{
                    width: 10,
                    height: 10,
                    marginRight: 1,
                    marginLeft: 1,
                  }}
                />
                bearbeiten
              </Text>
            </Link>
          </Flex>
          <Flex
            sx={{ marginTop: "-2px", alignItems: "baseline" }}
            direction="row"
          >
            <Text variant="body1">{internal?.label}</Text>
          </Flex>
        </Flex>
      )}
      <Flex
        bg="#e8e8e8"
        mt={2}
        pl={1}
        style={{
          width: showEdit ? "auto" : 0,
          height: showEdit ? "auto" : 0,
          opacity: showEdit ? 1 : 0,
          border: "none",
          borderBottom: touched && error ? "2px solid red" : "1px solid #777",
        }}
      >
        <Labeled label={label}>
          <GooglePlacesAutocomplete
            selectProps={{
              onBlur,
              onFocus,
              isRequired,
              isClearable: true,
              placeholder:
                "Veranstaltungsort oder Adresse" + (props.required ? "*" : ""),
              loadingMessage: () => "Lade...",
              noOptionsMessage: () => "Keine Ergebnisse",
              styles: {
                input: (base) => ({
                  ...base,
                }),
                control: (base) => ({
                  ...base,
                  backgroundColor: "#e8e8e8",
                  border: "none",
                }),
                container: (base) => ({
                  ...base,
                  marginTop: -25,
                }),
                singleValue: (base, state) => ({
                  ...base,
                  opacity: state.selectProps.menuIsOpen ? 0.5 : 1,
                }),
                clearIndicator: (base, state) => ({
                  ...base,
                  color: "black",
                }),
                dropdownIndicator: (base, state) => ({
                  ...base,
                  color: "black",
                }),
              },
              value: internal,
              onChange: (newValue) => {
                setDidChangeOnce(true);
                if (newValue) {
                  const {
                    value: { place_id },
                  } = newValue;
                  onChange({ target: { value: place_id } });
                } else {
                  setInternal(null);
                  onChange({ target: { value: null } });
                }
              },
            }}
          />
        </Labeled>
      </Flex>
      <Text id="maps-attribution" variant="caption">
        {""}
      </Text>
      {touched && error && (
        <Text variant="caption" ml={3} mt={1} color="error">
          {error}
        </Text>
      )}
    </Flex>
  );
};
export { AddressInput };
