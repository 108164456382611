const colors = {
  primary: { light: "#000000", dark: "#ffffff" },
  secondary: { light: "#444444", dark: "#aaaaaa" },
  background: { light: "#ffffff", dark: "#1F1F1F" },
  card: { light: "#ffffff", dark: "#303030" },
  shadow: { light: "#cfcfcf", dark: "#000000" },
  accent: { light: "#FF0047", dark: "#FF0047" },
  accentBG: { light: "#2d3590", dark: "#2d3590" },
  red: { light: "#dd4444", dark: "#ff4444" },
  blue: { light: "rgba(0, 122, 255, 1)", dark: "rgba(10, 132, 255, 1)" },
  indigo: { light: "rgba(88, 86, 214, 1)", dark: "rgba(94, 92, 230, 1)" },
  black: { light: "#000000", dark: "#000000" },
  white: { light: "#FFFFFF", dark: "#FFFFFF" },
  tabActive: { light: "#FF0047", dark: "#ffffff" },
  tabInactive: { light: "#777777", dark: "#888888" },
  tabBackground: { light: "#ffffff", dark: "#000000" },
};

export { colors };
