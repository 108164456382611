import React from "react";
import { useField } from "react-final-form";
import { Chip, Box, Tooltip } from "@material-ui/core";

const ChipRadioInput = ({ source, choices }) => {
  const {
    input: { onChange, value },
  } = useField(source);

  return (
    <Box display="flex" flexWrap="wrap">
      {choices.map(({ id, name, examples }) => (
        <Tooltip title={examples} key={id}>
          <Box key={id} mr={2} mb={2}>
            <Chip
              label={name}
              onClick={() => onChange({ target: { value: id } })}
              color={value === id ? "primary" : "default"}
            />
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};
export { ChipRadioInput };
