import React from "react";
import { Cloudinary } from "cloudinary-core";

var cl = new Cloudinary({ cloud_name: "hcabadqds" });

function CloudinaryAvatar({ handle = undefined, name = "No", size = 36 }) {
  return (
    <>
      {handle ? (
        <img
          style={{ width: size, height: size, borderRadius: size }}
          src={`${cl.url(handle, {
            transformation: [{ width: size, height: size, crop: "thumb" }],
          })}`}
          alt=""
        />
      ) : (
        <div
          style={{
            borderRadius: size,
            width: size,
            height: size,
            backgroundColor: "black",
          }}
        />
      )}
    </>
  );
}

export { CloudinaryAvatar };
