import React, { useState } from "react";
import {
  Edit,
  Button,
  SimpleForm,
  SaveButton,
  Toolbar,
  TextInput,
  maxLength,
  FormDataConsumer,
} from "react-admin";
import { CloudinaryInput } from "veranstalter/src/components/CloudinaryInput";
import { Box, InputAdornment } from "@material-ui/core";
import { Required, required } from "veranstalter/src/components/Required";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPhoneAlt,
  FaLink,
} from "react-icons/fa";
import { Flex, Text } from "veranstalter/src/components/Design";
import ActionDelete from "@material-ui/icons/Delete";
import { CloudinaryAvatar } from "veranstalter/src/components/CloudinaryAvatar";

import { useMediaQuery } from "@material-ui/core";

import { useField } from "react-final-form";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@material-ui/core";

function ProfileToolbar(props) {
  const deleteInfo = `
Du kannst uns hier gern noch was schreiben, aber es ist nicht nötig.



----
Name: ${props.record.name}
ID: ${props.record.id}
`;
  const deleteMailto = `mailto:veranstalter@kulta.app?subject=${encodeURIComponent(
    "Profil Löschen"
  )}&body=${encodeURIComponent(deleteInfo)}`;
  const forceRenderKey = String(props.pristine) + String(props.invalid);
  const [showDialog, setShowDialog] = useState(false);
  const th = useTheme();
  return (
    <Toolbar {...props} key={forceRenderKey}>
      <SaveButton icon={<div />} redirect="/" submitOnEnter={true} />
      <Flex flex={1} />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label="Konto löschen"
      >
        <DialogTitle>Konto löschen</DialogTitle>
        <DialogContent>
          <Text>
            Schade, dass du dein Profil löschen möchtest 😭 Wenn du dein Profil
            löschst, werden sowohl dein Profil, als auch deine erstellten
            Events, gelöscht.
          </Text>
          <Text mt={2}>
            Aktuell können wir Nutzerkonten leider nur manuell löschen. Sende
            uns hierzu eine E-Mail mit deiner Nutzer-ID an
            <a href={deleteMailto}>veranstalter@kulta.app</a>.
          </Text>
          <Text mt={2}>{`Deine Nutzer-ID lautet: ${props.record.id}`}</Text>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={() => setShowDialog(false)}
          />
        </DialogActions>
      </Dialog>
      <Button
        onClick={(e) => {
          e.preventDefault();
          setShowDialog(true);
        }}
        style={{ color: th.palette.error.dark }}
        label={"Konto Löschen"}
      >
        <ActionDelete />
      </Button>
    </Toolbar>
  );
}

function TextInputCharacterCount({ maxCharacters, errorText, ...props }) {
  const {
    input: { value },
  } = useField(props.source);
  return (
    <TextInput
      {...props}
      source={props.source}
      validate={[
        ...(props.validate || []),
        maxLength(maxCharacters, errorText),
      ]}
      inputProps={{ ...(props.inputProps || []), maxLength: maxCharacters }}
      InputProps={{
        ...(props.InputProps || []),
        endAdornment: (
          <InputAdornment position="end">
            <Text>{`${value.length}/${maxCharacters}`}</Text>
          </InputAdornment>
        ),
      }}
    />
  );
}

function InAppPreview({ profile }) {
  return (
    <Flex
      direction="row"
      alignItems="center"
      p={3}
      flex={1}
      borderRadius={12}
      style={{
        boxShadow: "0px 0px 30px rgba(0,0,0,0.1)",
        opacity: !profile.name ? 0.5 : 1,
      }}
    >
      <CloudinaryAvatar
        size={48}
        handle={profile.image_handle}
        name={profile.name}
      />
      <Flex ml={2} flex={1}>
        <Text bold variant="body1">
          {profile.name || "Profilname"}
        </Text>
        {profile.tagline && <Text variant="body2">{profile.tagline}</Text>}
      </Flex>
      <Flex
        py={1}
        px={2}
        borderColor="primary"
        border="2px solid"
        borderRadius={4}
      >
        <Text variant="caption">Folgen</Text>
      </Flex>
    </Flex>
  );
}

function ProfileFieldsEssential() {
  const mobile = useMediaQuery("(max-width:640px)");
  return (
    <>
      <Flex direction="row">
        <Flex width={150}>
          <CloudinaryInput
            imageTypeLabel="Profilbild"
            inputStyle="avatar"
            source="image_handle"
          />
        </Flex>
        <Flex ml={3} flex={1}>
          <TextInput
            validate={required}
            required
            source="name"
            label="Profilname"
            fullWidth
          />
          <TextInputCharacterCount
            maxCharacters={24}
            errorText="Die Tagline muss kürzer als 20 Zeichen sein."
            source="tagline"
            label="Kurzbeschreibung"
            fullWidth
          />
        </Flex>
      </Flex>
      <Flex
        my={5}
        mx={mobile ? 3 : 8}
        direction="row"
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        <FormDataConsumer>
          {({ formData }) => <InAppPreview profile={formData} />}
        </FormDataConsumer>
      </Flex>
      {/*< ReferenceInput
        validate={required}
        required
        defaultValue="muenster"
        source="city_id"
        fullWidth
        label="Region"
        reference="cities"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>*/}
    </>
  );
}

function ProfileFieldsDetails() {
  return (
    <>
      <TextInput
        source="description"
        label="Beschreibung"
        multiline
        rows={5}
        fullWidth
      />
      <TextInput
        source="contact"
        label="Ansprechpartner / Kontaktmöglichkeit (nicht öffentlich)"
        multiline
        rows={2}
        fullWidth
      />
      <TextInput
        source="phone"
        label="Telefonnummer"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaPhoneAlt color="#888" />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        source="website"
        label="Webseite (URL)"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaLink color="#888" />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        source="facebookPage_url"
        label="Facebook Seite (URL)"
        fullWidth
        placeholder="https://www.facebook.com/mypage/"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaFacebook color="#1877f2" />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        source="instagram_username"
        label="Instagram @name"
        fullWidth
        InputProps={{
          startAdornment: (
            <Flex mt={3} direction="row" alignItems="center">
              <FaInstagram color="#c32aa3" />
              <Text ml={2} mr="2px">
                @
              </Text>
            </Flex>
          ),
        }}
      />
      <TextInput
        source="twitter_username"
        label="Twitter @name"
        fullWidth
        InputProps={{
          startAdornment: (
            <Flex mt={3} direction="row" alignItems="center">
              <FaTwitter color="#1da1f2" />
              <Text ml={2} mr="2px">
                @
              </Text>
            </Flex>
          ),
        }}
      />
    </>
  );
}

function ProfileEdit(props) {
  return (
    <Box maxWidth="600px">
      <Edit mutationMode={"optimistic"} {...props} title="Profil">
        <SimpleForm redirect="/" toolbar={<ProfileToolbar />}>
          <ProfileFieldsEssential />
          <ProfileFieldsDetails />
        </SimpleForm>
      </Edit>
      <Required />
    </Box>
  );
}

export { ProfileEdit, ProfileFieldsEssential, ProfileFieldsDetails };
