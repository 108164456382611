import React from "react";
import {
  Button as MDButton,
  ButtonProps as MDButtonProps,
} from "@material-ui/core";
import { Icon, IconName } from "veranstalter/src/components/Icon";
import { Flex } from "veranstalter/src/components/Design";
interface ButtonProps extends MDButtonProps {
  secondary?: boolean;
  danger?: boolean;
  icon?: IconName;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: string;
  inherit?: boolean;
  colorOverride?: string;
  iconColor?: string;
}

function Button({
  secondary = false,
  children,
  danger = false,
  inherit = false,
  icon,
  iconColor = undefined,
  onClick,
  colorOverride,
  ...rest
}: ButtonProps) {
  const color = danger ? "error" : secondary ? "primary" : "primary";
  return (
    <MDButton
      {...rest}
      onClick={onClick}
      variant={secondary ? undefined : "contained"}
      //@ts-ignore
      color={inherit ? "inherit" : color}
      style={colorOverride ? { backgroundColor: colorOverride } : undefined}
      startIcon={
        icon && (
          <Icon
            name={icon}
            size={18}
            color={
              iconColor
                ? iconColor
                : inherit
                ? "black"
                : secondary
                ? danger
                  ? "red"
                  : "black"
                : "white"
            }
          />
        )
      }
    >
      <Flex color={inherit ? "util.black" : undefined}>{children}</Flex>
    </MDButton>
  );
}

export { Button };
